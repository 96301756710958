import parse from 'parse-color';
import { api } from '../api';

export function useLightMode(): boolean {
    try {
        return parse(api.theme?.mainContentBackgroundColour || '').hsl[2] > 127;
    } catch (err) {
        return false;
    }
}
