import React from 'react';
import { api } from '../api';
import { ComponentWrapper, DynamicGrid, JsonPropertiesResponse } from '@iotnxt-dev/reactcomponents';
import { Loading } from './loading';
import { colouredStatus, overrideAssetSN, overrideSite, overrideStatus, overrideStatusDate } from './gridOverrides';
import { GridConfigType } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/interfaces';
import { API as DynamicGridAPI } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/api';
import { useLightMode } from '../utils';

interface Props {}

interface State {
    jsonProperties?: JsonPropertiesResponse;
}

export class AllTags extends React.Component<Props, State> {
    state: State = {};

    isLocked = false;

    config?: GridConfigType;

    componentDidMount = async (): Promise<void> => {
        // ----------------------------------------
        // Get GetJsonProperties
        // We get the jsonproperties column data, then override status and assetType enum values
        if (!api || !api.token) {
            throw new Error('missing api or token');
        }
        this.config = {
            server: '',
            apiPath: '/api/AllTagsDynamicGrid',
            authorization: 'Bearer ' + api.token.access_token,
        };
        const dynamicGridApi = new DynamicGridAPI();
        dynamicGridApi.config = this.config;
        dynamicGridApi.headers.authorization = this.config.authorization;

        const [statuses, assetTypes, getJsonProperties] = await Promise.all([
            api.DropDowns.GetStatuses(),
            api.DropDowns.GetAssetTypes(),
            dynamicGridApi.GetJsonProperties(),
        ]);
        const jsonProperties = getJsonProperties.data;

        if (statuses.data && assetTypes.data && jsonProperties) {
            // override GetJsonProperties status and assetType
            jsonProperties.columns['status'].values = statuses.data.map((s) => s.name);
            jsonProperties.columns['assetType'].values = assetTypes.data.map((a) => a.name);
            this.setState({ jsonProperties });
        }
        // ----------------------------------------
    };

    render(): JSX.Element {
        if (!api.token || !this.state.jsonProperties) return <Loading />;

        return (
            <ComponentWrapper>
                <h1 style={{ marginLeft: 25 }}>Tags</h1>
                <DynamicGrid
                    useLightMode={useLightMode()}
                    key={'alltags'}
                    autoRefresh={true}
                    useHistory={false}
                    useImport={true}
                    useExport={true}
                    pageSize={15}
                    isLocked={this.isLocked}
                    orderByColumn="statusDate"
                    orderByColumnSorting="DESC"
                    jsonPropertiesOverride={this.state.jsonProperties}
                    config={this.config}
                    conditionalOverrides={[overrideAssetSN, overrideStatus, overrideSite, overrideStatusDate]}
                    conditionalRowStyle={colouredStatus}
                />
            </ComponentWrapper>
        );
    }
}
