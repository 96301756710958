/** we build our own input dropdown box because the default
 * wont allow you to see full list if you have text entered
 */

import * as React from 'react';
import styled from 'styled-components';

// tslint:disable-next-line: no-empty-interface
interface Props {
    onChange?: (event: { target: { value: unknown } }) => void;
    placeholder?: string;
    value: string;
    datalist: OptionType[];

    [index: string]: unknown;
}

interface OptionType {
    value: string;
    text: string;
}

interface State {
    value: string;
    dropdown: boolean;
    width: number;
}

const DropdownButton = styled.div`
    width: 32px;
    height: 32px;
    background: white;
    border: 1px solid lightgray;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    :hover {
        border: 1px solid gray;
    }
`;

const InputWrap = styled.div`
    display: flex;
    flex-direction: row;

    input {
        margin: 0;
        height: 32px;
        margin: 0;
    }
`;

const DropdownList = styled.div`
    padding-bottom: 2px;
    background: rgb(250, 250, 250);
    border: 2px solid lightgray;
    border-top: 1px solid lightgray;
    margin-right: 52px;
    max-height: 300px;
    overflow-y: scroll;
    position: absolute;
`;

const DropdownOption = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid lightgray;
    font-weight: normal;
    font-size: 16px;
    :hover {
        background: #ddd;
    }
`;

export class InputSelect extends React.Component<Props, State> {
    state = {
        value: '',
        datalist: this.props.datalist ? this.props.datalist : [],
        dropdown: false,
        width: 100,
    };

    dropdownlist: HTMLDivElement | undefined;

    componentDidMount(): void {
        this.autosizeDropdown();
        window.addEventListener('resize', this.autosizeDropdown, false);
    }

    componentWillUnmount = (): void => {
        window.removeEventListener('resize', this.autosizeDropdown, false);
    };

    autosizeDropdown = (): void => {
        if (this.dropdownlist) {
            this.setState({ width: this.dropdownlist.clientWidth - 32 });
        }
    };

    onChange = (event: { target: { value: unknown } }): void => {
        // pass event up the stack
        if (this.props.onChange) this.props.onChange(event);
    };

    render(): JSX.Element {
        return (
            <div
                ref={(el) => {
                    if (el) this.dropdownlist = el;
                }}
            >
                <InputWrap>
                    <input
                        value={this.state.value}
                        placeholder={this.props.placeholder}
                        onChange={(event) => {
                            this.setState({ value: event.target.value, dropdown: false });
                            this.onChange(event);
                        }}
                    />

                    <DropdownButton
                        style={{ width: 32 }}
                        onClick={() => {
                            this.setState({ dropdown: !this.state.dropdown });
                        }}
                    >
                        <i className="fas fa-chevron-down" />
                    </DropdownButton>
                </InputWrap>
                {this.state.dropdown && (
                    <DropdownList style={{ width: this.state.width }}>
                        {this.state.datalist.map((option, key) => {
                            return (
                                <DropdownOption
                                    key={key}
                                    onClick={() => {
                                        this.setState({ value: option.value, dropdown: false });
                                        this.onChange({ target: { value: option.value } });
                                    }}
                                >
                                    {option.text}
                                </DropdownOption>
                            );
                        })}
                    </DropdownList>
                )}
            </div>
        );
    }
}
