import React from 'react';
import { DropdownsBlock } from './dropdownsBlock';
import styled from 'styled-components';
import { api } from '../api';
import { DropdownForm } from './dropdownForm';
import { DropdownList } from './dropdownList';
import { CloudAppResponse } from '../shared/cloudappresponse';
import { CloudAppResponseDisplay, InputEdit } from './styledComponents';
import { constants } from '../shared/appsettings';
import { AssetType, IState, Shift, Site, Status, TagType } from '../interfaces';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
`;

interface State {
    sites?: Site[];
    shifts?: Shift[];
    states?: IState[];
    tagTypes?: TagType[];
    assetTypes?: AssetType[];
    statuses?: Status[];
    // forms
    siteAdd: boolean;
    businessGroupAdd: boolean;
    assetTypeAdd: boolean;
    stateAdd: boolean;
    statusAdd: boolean;
    shiftAdd: boolean;
    // formContent
    formContent: string;
    // lists
    sitesList: boolean;
    shiftsList: boolean;
    statesList: boolean;
    TagTypesList: boolean;
    AssetTypesList: boolean;
    StatusList: boolean;
    // responses (timeout errors)
    response_getSites?: CloudAppResponse<unknown>;
    response_getTagTypes?: CloudAppResponse<unknown>;
    response_getAssetTypes?: CloudAppResponse<unknown>;
    response_getStates?: CloudAppResponse<unknown>;
    response_getStatus?: CloudAppResponse<unknown>;
    response_getShifts?: CloudAppResponse<unknown>;

    response_siteAdd?: CloudAppResponse<unknown>;
    response_businessGroupAdd?: CloudAppResponse<unknown>;
    response_assetTypeAdd?: CloudAppResponse<unknown>;
    response_stateAdd?: CloudAppResponse<unknown>;
    response_statusAdd?: CloudAppResponse<unknown>;
    response_shiftAdd?: CloudAppResponse<unknown>;
}

interface Props {}

export class DropdownsPage extends React.Component<Props, State> {
    state: State = {
        sites: undefined,
        shifts: undefined,
        states: undefined,
        tagTypes: undefined,
        assetTypes: undefined,
        statuses: undefined,
        // forms
        siteAdd: false,
        businessGroupAdd: false,
        assetTypeAdd: false,
        stateAdd: false,
        statusAdd: false,
        shiftAdd: false,
        // formContent
        formContent: '',
        // lists
        sitesList: false,
        shiftsList: false,
        statesList: false,
        TagTypesList: false,
        AssetTypesList: false,
        StatusList: false,
        // responses (errors)
        response_siteAdd: undefined,
        response_businessGroupAdd: undefined,
        response_assetTypeAdd: undefined,
        response_stateAdd: undefined,
        response_statusAdd: undefined,
        response_shiftAdd: undefined,
        // timeouts on gets
        response_getSites: undefined,
        response_getTagTypes: undefined,
        response_getAssetTypes: undefined,
        response_getStates: undefined,
        response_getStatus: undefined,
        response_getShifts: undefined,
    };

    // timeout handles
    timeout_getSites: NodeJS.Timeout | undefined;
    timeout_getTagTypes: NodeJS.Timeout | undefined;
    timeout_getAssetTypes: NodeJS.Timeout | undefined;
    timeout_getStates: NodeJS.Timeout | undefined;
    timeout_getStatuses: NodeJS.Timeout | undefined;
    timeout_getShifts: NodeJS.Timeout | undefined;

    componentDidMount(): void {
        this.getData();
        document.title = 'Conoco Phillips | Set Up Dropdowns';
    }

    componentWillUnmount(): void {
        // forget about pending timeouts if we browse away
        if (this.timeout_getSites) clearTimeout(this.timeout_getSites);
        if (this.timeout_getTagTypes) clearTimeout(this.timeout_getTagTypes);
        if (this.timeout_getAssetTypes) clearTimeout(this.timeout_getAssetTypes);
        if (this.timeout_getStates) clearTimeout(this.timeout_getStates);
        if (this.timeout_getStatuses) clearTimeout(this.timeout_getStatuses);
        if (this.timeout_getShifts) clearTimeout(this.timeout_getShifts);
    }

    getData(): void {
        // https://gijima-api.qa.iotnxt.io/swagger/index.html
        this.getSites();
        this.getStates();
        this.getTagTypes();
        this.getAssetTypes();
        this.getStatuses();
    }

    // refactored for reuse
    getSites(): void {
        this.getWithTimeout(this.timeout_getSites, 'response_getSites', '/api/DropDowns/GetSites', 'sites');
    }

    getStates(): void {
        this.getWithTimeout(this.timeout_getStates, 'response_getStates', '/api/DropDowns/GetStates', 'states');
    }

    getTagTypes(): void {
        this.getWithTimeout(this.timeout_getTagTypes, 'response_getTagTypes', '/api/DropDowns/GetTagTypes', 'tagTypes');
    }

    getAssetTypes(): void {
        this.getWithTimeout(
            this.timeout_getAssetTypes,
            'response_getAssetTypes',
            '/api/DropDowns/GetAssetTypes',
            'assetTypes',
        );
    }

    getStatuses(): void {
        this.getWithTimeout(this.timeout_getStatuses, 'response_getStatuses', '/api/DropDowns/GetStatuses', 'statuses');
    }

    getWithTimeout(
        timeoutHandle: NodeJS.Timeout | undefined,
        response_timeout: string,
        apipath: string,
        stateprop: string,
    ): void {
        // start a timeout incase fetch takes too long
        timeoutHandle = setTimeout(() => {
            const stateupdate = {} as State;
            const mapped = {
                [response_timeout]: { isSuccessful: false, message: 'Timeout' },
            };

            console.log(`Get right timeout: ${JSON.stringify(mapped, null, 2)}`);

            this.setState({ ...stateupdate, ...mapped });
            console.error(new Error('GET ' + apipath + ' timeout.'));
        }, constants.timeoutMs);

        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + apipath, { headers: { Authorization: 'Bearer ' + api.token.access_token } })
            .then((res) => res.json())
            .then((response: CloudAppResponse<unknown[]>) => {
                const stateResponse = {} as State & Record<string, unknown>;
                stateResponse[stateprop] = response.data;
                stateResponse[response_timeout] = undefined;
                this.setState(stateResponse as State);
                if (timeoutHandle) clearTimeout(timeoutHandle);
            })
            .catch((err) => {
                const stateError = {} as State & { [index: string]: unknown };
                stateError[stateprop] = [];
                stateError[response_timeout] = { isSuccessful: false, message: err.message };
                this.setState(stateError);
                if (timeoutHandle) clearTimeout(timeoutHandle);
            });
    }

    upsertData(
        url: string,
        body: { [index: string]: unknown },
        cb: (response: CloudAppResponse<unknown>) => void,
    ): void {
        if (!api.token) throw new Error('missing token');

        fetch(url, {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + api.token.access_token,
                Accept: 'application/json',
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((response: CloudAppResponse<unknown>) => {
                cb(response);
            })
            .catch((err) => {
                cb(err);
            });
    }

    render(): JSX.Element {
        return (
            <div
                style={{
                    background: 'white',
                    maxWidth: 1000,
                    margin: '0 auto',
                    marginBottom: 50,
                }}
            >
                <div style={{ padding: 5, background: 'rgba(0,0,0,0.05)' }}>
                    {/* =================== Forms ================== */}

                    {this.state.siteAdd && (
                        <DropdownForm
                            title="Sites - Add New"
                            apply={async () => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertSite',
                                    { siteId: '', siteName: this.state.formContent },
                                    (response) => {
                                        if (!response.isSuccessful) {
                                            this.setState({ response_siteAdd: response });
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({
                                                siteAdd: false,
                                                formContent: '',
                                                response_siteAdd: undefined,
                                            });
                                            this.getSites(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ siteAdd: false, response_siteAdd: undefined });
                            }}
                        >
                            Site Name:
                            <br />
                            <InputEdit
                                placeholder="Type Site Name here..."
                                type="text"
                                value={this.state.formContent}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const { value } = target as HTMLInputElement;
                                    this.setState({ formContent: value });
                                }}
                            />
                            <CloudAppResponseDisplay data={this.state.response_siteAdd} />
                        </DropdownForm>
                    )}

                    {this.state.businessGroupAdd && (
                        <DropdownForm
                            title="Tag Type - Add New"
                            apply={async () => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertTagType',
                                    {
                                        tagTypeId: '',
                                        tagTypeName: this.state.formContent,
                                    },
                                    (response) => {
                                        if (!response.isSuccessful) {
                                            this.setState({ response_businessGroupAdd: response });
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({
                                                businessGroupAdd: false,
                                                formContent: '',
                                                response_businessGroupAdd: undefined,
                                            });
                                            this.getTagTypes(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ businessGroupAdd: false, response_businessGroupAdd: undefined });
                            }}
                        >
                            Tag Type Name:
                            <br />
                            <InputEdit
                                placeholder="Tag Type Name here..."
                                type="text"
                                value={this.state.formContent}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const { value } = target as HTMLInputElement;
                                    this.setState({ formContent: value });
                                }}
                            />
                            <CloudAppResponseDisplay data={this.state.response_businessGroupAdd} />
                        </DropdownForm>
                    )}

                    {this.state.assetTypeAdd && (
                        <DropdownForm
                            title="Asset Type - Add New"
                            apply={async () => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertAssetType',
                                    {
                                        assetTypeId: '',
                                        assetTypeName: this.state.formContent,
                                    },
                                    (response) => {
                                        if (!response.isSuccessful) {
                                            this.setState({ response_assetTypeAdd: response });
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({
                                                assetTypeAdd: false,
                                                formContent: '',
                                                response_assetTypeAdd: undefined,
                                            });
                                            this.getAssetTypes(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ assetTypeAdd: false, response_assetTypeAdd: undefined });
                            }}
                        >
                            Asset Type Name:
                            <br />
                            <InputEdit
                                placeholder="Asset Type Name here..."
                                type="text"
                                value={this.state.formContent}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const { value } = target as HTMLInputElement;
                                    this.setState({ formContent: value });
                                }}
                            />
                            <CloudAppResponseDisplay data={this.state.response_assetTypeAdd} />
                        </DropdownForm>
                    )}

                    {this.state.stateAdd && (
                        <DropdownForm
                            title="States - Add New"
                            apply={async () => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertState',
                                    {
                                        stateId: '',
                                        stateName: this.state.formContent,
                                    },
                                    (response) => {
                                        if (!response.isSuccessful) {
                                            this.setState({ response_stateAdd: response });
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({
                                                stateAdd: false,
                                                formContent: '',
                                                response_stateAdd: undefined,
                                            });
                                            this.getStates(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ stateAdd: false, response_stateAdd: undefined });
                            }}
                        >
                            State Name:
                            <br />
                            <InputEdit
                                placeholder="State Name here..."
                                type="text"
                                value={this.state.formContent}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const { value } = target as HTMLInputElement;
                                    this.setState({ formContent: value });
                                }}
                            />
                            <CloudAppResponseDisplay data={this.state.response_stateAdd} />
                        </DropdownForm>
                    )}

                    {this.state.statusAdd && (
                        <DropdownForm
                            title="Status - Add New"
                            apply={async () => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertStatus',
                                    {
                                        statusId: '',
                                        statusName: this.state.formContent,
                                    },
                                    (response) => {
                                        if (!response.isSuccessful) {
                                            this.setState({ response_statusAdd: response });
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({
                                                statusAdd: false,
                                                formContent: '',
                                                response_statusAdd: undefined,
                                            });
                                            this.getStatuses(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ statusAdd: false, response_statusAdd: undefined });
                            }}
                        >
                            Status Name:
                            <br />
                            <InputEdit
                                placeholder="Status Name here..."
                                type="text"
                                value={this.state.formContent}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const { value } = target as HTMLInputElement;
                                    this.setState({ formContent: value });
                                }}
                            />
                            <CloudAppResponseDisplay data={this.state.response_statusAdd} />
                        </DropdownForm>
                    )}

                    {/* ================================== Lists ===================== */}

                    {this.state.sitesList && (
                        <DropdownList
                            list={this.state.sites}
                            title="Sites"
                            onChange={(sites) => {
                                this.setState({ sites });
                            }}
                            apply={(body, cb) => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertSite',
                                    { siteId: body.id, siteName: body.name },
                                    (response) => {
                                        cb(response);
                                        if (!response.isSuccessful) {
                                            console.error(response);
                                        }
                                        if (response.isSuccessful) {
                                            this.getSites(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ sitesList: false });
                            }}
                        />
                    )}

                    {this.state.statesList && (
                        <DropdownList
                            list={this.state.states}
                            title="States"
                            onChange={(states) => {
                                this.setState({ states });
                            }}
                            apply={(body, cb) => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertState',
                                    { stateId: body.id, stateName: body.name },
                                    (response) => {
                                        cb(response);
                                        if (!response.isSuccessful) {
                                            console.error(response);
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({ formContent: '' });
                                            this.getStates(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ statesList: false });
                            }}
                        />
                    )}

                    {this.state.TagTypesList && (
                        <DropdownList
                            list={this.state.tagTypes}
                            title="Tag Types"
                            onChange={(tagTypes) => {
                                this.setState({ tagTypes });
                            }}
                            apply={(body, cb) => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertTagType',
                                    { tagTypeId: body.id, tagTypeName: body.name },
                                    (response) => {
                                        cb(response);
                                        if (!response.isSuccessful) {
                                            console.error(response);
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({ formContent: '' });
                                            this.getTagTypes(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ TagTypesList: false });
                            }}
                        />
                    )}

                    {this.state.AssetTypesList && (
                        <DropdownList
                            list={this.state.assetTypes}
                            title="Asset Types"
                            onChange={(assetTypes) => {
                                this.setState({ assetTypes });
                            }}
                            apply={(body, cb) => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertAssetType',
                                    { assetTypeId: body.id, assetTypeName: body.name },
                                    (response) => {
                                        cb(response);
                                        if (!response.isSuccessful) {
                                            console.error(response);
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({ formContent: '' });
                                            this.getAssetTypes(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ AssetTypesList: false });
                            }}
                        />
                    )}

                    {this.state.StatusList && (
                        <DropdownList
                            list={this.state.statuses}
                            title="Status"
                            onChange={(statuses) => {
                                this.setState({ statuses });
                            }}
                            apply={(body, cb) => {
                                this.upsertData(
                                    api.apiUrl + '/api/DropDowns/UpsertStatuses',
                                    { id: body.id, name: body.name },
                                    (response) => {
                                        cb(response);
                                        if (!response.isSuccessful) {
                                            console.error(response);
                                        }
                                        if (response.isSuccessful) {
                                            this.setState({ statusAdd: false, formContent: '' });
                                            this.getStatuses(); // updates list.
                                        }
                                    },
                                );
                            }}
                            close={() => {
                                this.setState({ StatusList: false });
                            }}
                        />
                    )}

                    <div>
                        <Row>
                            <DropdownsBlock
                                title="Sites"
                                icon="fal fa-map-marker-alt fa-4x"
                                addClick={() => {
                                    this.setState({ siteAdd: true });
                                }}
                                itemsClick={() => {
                                    this.setState({ sitesList: true });
                                }}
                                response={this.state.response_getSites}
                                items={this.state.sites}
                            />

                            <DropdownsBlock
                                title="Tag Type"
                                icon="fal fa-tag fa-4x"
                                addClick={() => {
                                    this.setState({ businessGroupAdd: true });
                                }}
                                itemsClick={() => {
                                    this.setState({ TagTypesList: true });
                                }}
                                response={this.state.response_getTagTypes}
                                items={this.state.tagTypes}
                            />

                            <DropdownsBlock
                                title="Asset Type"
                                icon="fal fa-sensor fa-4x"
                                addClick={() => {
                                    this.setState({ assetTypeAdd: true });
                                }}
                                itemsClick={() => {
                                    this.setState({ AssetTypesList: true });
                                }}
                                response={this.state.response_getAssetTypes}
                                items={this.state.assetTypes}
                            />
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <DropdownsBlock
                                title="States"
                                icon="fal fa-map-marker fa-4x"
                                addClick={() => {
                                    this.setState({ stateAdd: true });
                                }}
                                itemsClick={() => {
                                    this.setState({ statesList: true });
                                }}
                                response={this.state.response_getStates}
                                items={this.state.states}
                            />

                            <DropdownsBlock
                                title="Status"
                                icon="fal fa-toggle-on fa-4x"
                                addClick={() => {
                                    this.setState({ statusAdd: true });
                                }}
                                itemsClick={() => {
                                    this.setState({ StatusList: true });
                                }}
                                response={this.state.response_getStatus}
                                items={this.state.statuses}
                            />
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
