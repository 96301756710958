import React from 'react';
import { api } from '../api';

interface Profile {
    id?: string;
    firstName?: string;
    fullName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    phoneNumber?: string;
    status?: string;
    roles?: unknown[];
    sites?: unknown[];
}

interface Props {}

interface State {
    profile?: Profile;
}

export class Welcome extends React.Component<Props, State> {
    state: State = {
        profile: undefined,
    };

    componentDidMount(): void {
        // fetch(api.apiUrl + '/api/v1/Users/Profile',
        //     {
        //         method: 'get',
        //         headers: {
        //             Authorization: 'Bearer ' + api.token.access_token
        //         }
        //     })
        //     .then(res => res.json())
        //     .then((profile: Profile) => {
        //         this.setState({ profile })
        //     })
        //     .catch(err => { console.log(err); });
        document.title = 'Conoco Phillips | Welcome';
    }

    render(): JSX.Element {
        if (api.account === undefined) {
            return <div>loading...</div>;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <h1>Welcome, {api.account.preferred_username}</h1>
                <img
                    style={{
                        backgroundColor: 'white',
                    }}
                    src="/conocophillips.png"
                    alt="logo"
                />
            </div>
        );
    }
}
