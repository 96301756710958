import React from 'react';
import { api } from '../api';
import { Loading } from './loading';
import { Site } from '../interfaces';
import { ComponentWrapper, DynamicGrid, JsonPropertiesResponse, PageResultResponse } from '@iotnxt-dev/reactcomponents';
import { API as DynamicGridAPI } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/api';
import { AssetComponent } from './assets';
import {
    StyledTable,
    StyledTableRow,
} from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/dynamic-grid';

interface State {
    /** Site data */
    sitePagedInstancesResponse?: PageResultResponse<Site>;
    /** columns data */
    jsonProperties?: JsonPropertiesResponse;
}

interface Props {
    site?: string;
    id?: string;
}

export class SitePage extends React.Component<Props, State> {
    state: State = {};

    siteApi = new DynamicGridAPI();

    componentDidMount(): void {
        if (!api.token) return;

        this.siteApi.config = {
            apiPath: '/api/SitesDynamicGrid',
            server: '',
            authorization: 'Bearer ' + api.token.access_token,
        };

        this.siteApi.headers.authorization = this.siteApi.config.authorization;

        if (this.props.site) this.getData();
    }

    getData = async (): Promise<void> => {
        const sitePagedInstancesResponse = await this.siteApi.getPagedInstances<Site>({
            orderByQuery: 'totalAssets DESC',
            pageNumber: 1,
            pageSize: 20,
            selectQuery: '',
            whereQuery: { name: `%${this.props.site}%` },
        });

        const jsonProperties = await this.siteApi.GetJsonProperties().then((res) => res.data);

        this.setState({ sitePagedInstancesResponse, jsonProperties });
    };

    render(): JSX.Element {
        if (
            !this.state.sitePagedInstancesResponse ||
            !this.state.sitePagedInstancesResponse.data ||
            !this.state.jsonProperties
        ) {
            return <Loading text="Loading site..." />;
        }
        // if (!this.state.assets || !this.state.assets.data) return <Loading color={'white'} text="Loading assets..." />;

        const site = this.state.sitePagedInstancesResponse.data[0];

        // order columns
        const tempGridInstance = new DynamicGrid({});
        tempGridInstance.state.jsonProperties = this.state.jsonProperties;
        const orderedColumns = tempGridInstance.getOrderedColumns();

        return (
            <>
                <ComponentWrapper>
                    <div style={{ padding: 10 }}>
                        <h1 style={{ margin: 0, padding: 0, marginBottom: 5 }}>Site: {site.name}</h1>
                        <StyledTable>
                            <table style={{ maxWidth: 500 }}>
                                <tbody>
                                    {orderedColumns.map((k, i) => {
                                        // checks before rendering
                                        if (
                                            !k.columnProperties.isVisible ||
                                            !this.state.sitePagedInstancesResponse ||
                                            !this.state.sitePagedInstancesResponse.data ||
                                            !this.state.sitePagedInstancesResponse.data[0]
                                        )
                                            return null;

                                        const site = this.state.sitePagedInstancesResponse.data[0];
                                        const val = site[k.columnDataName as keyof Site];
                                        return (
                                            <StyledTableRow key={i}>
                                                <td style={{ padding: 5 }}>{k.columnProperties.displayName}:</td>
                                                <td style={{ padding: 5 }}>{val}</td>
                                            </StyledTableRow>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </StyledTable>
                    </div>
                </ComponentWrapper>

                {/* List of assets on this site */}
                <AssetComponent site={this.props.site} />
            </>
        );
    }
}
