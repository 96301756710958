import React from 'react';
import { Link } from 'react-router-dom';
import { Asset } from '../interfaces';
import { ConditionalOverride } from '@iotnxt-dev/reactcomponents';
import Moment from 'react-moment';
import { api } from '../api';
import { convertHexToRGBA } from '@iotnxt-dev/shared';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const colourKeys: any = {
    commissioned: api.theme ? api.theme.noAlertStatusColour : '#82d260',
    Decommissioned: api.theme ? api.theme.highAlertStatusColour : '#e06568',
    'In Storage': api.theme ? api.theme.lowAlertStatusColour : '#65a0e0',
    'In Transit': '#E57919',
};

// ------------------------------------------------------------------

const OverRideAssetSNComponent = (props: { value: string & unknown; row: Asset }): JSX.Element => (
    <div style={{ whiteSpace: 'nowrap', padding: 5 }}>
        <Link to={`/assets/${props.row.assetSiNumber}`}>
            <i className="fal fa-link" /> {props.row.assetSiNumber}{' '}
        </Link>
    </div>
);

export const overrideAssetSN: ConditionalOverride = {
    onColumnName: 'assetSiNumber',
    component: OverRideAssetSNComponent,
};

// ------------------------------------------------------------------

const OverRideSiteComponent = (props: { value: string & unknown; row: Asset }): JSX.Element => (
    <div style={{ whiteSpace: 'nowrap', padding: 5 }}>
        <Link to={`/sites?site=${props.row.site}&fullscreen=true`}>
            <i className="fas fa-bell" /> {props.row.site}
        </Link>
    </div>
);

export const overrideSite: ConditionalOverride = {
    onColumnName: 'site',
    component: OverRideSiteComponent,
};

// ------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatDateComponent = (props: { value: string & unknown; row: any }): JSX.Element => {
    return (
        <Moment format="YYYY/MM/DD HH:mm:ss" style={{ whiteSpace: 'nowrap', padding: 5 }}>
            {props.value}
        </Moment>
    );
};

export const overrideStatusDate = {
    onColumnName: 'statusDate',
    component: FormatDateComponent,
};

// ------------------------------------------------------------------

export const getColourKeyCaseInsensitive = (input: string): string => {
    const defaultColour = 'rgb(125,125,125)';
    if (!input) return defaultColour;
    if (typeof input !== 'string') return defaultColour;
    // copy to lowercase props
    const colours = JSON.parse(JSON.stringify(colourKeys).toLowerCase());
    if (colours[input.toLowerCase()]) return colours[input.toLowerCase()];
    return defaultColour;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OverRideStatusComponent = (props: { value: string & unknown; row: any }): JSX.Element => {
    // do lookup
    return (
        <div
            style={{
                color: getColourKeyCaseInsensitive(props.value),
                fontWeight: 600,
                whiteSpace: 'nowrap',
                padding: 5,
            }}
        >
            {props.value}
        </div>
    );
};

export const overrideStatus = {
    onColumnName: 'status',
    component: OverRideStatusComponent,
};

// ------------------------------------------------------------------

export const colouredStatus = (entry: { [index: string]: string }): React.CSSProperties => {
    const style: React.CSSProperties = {};
    if (colourKeys && entry['status']) {
        style.backgroundColor = convertHexToRGBA(getColourKeyCaseInsensitive(entry['status']), 0.1);
    }
    return style;
};
