import React from 'react';
import { api } from '../api';
import { CloudAppResponse } from '../shared/cloudappresponse';
import {
    applyFilteredPagingArgs,
    ComponentWrapper,
    DynamicGrid,
    FilteredArgs,
    IMultiSelectDataType,
    MultiSelect,
} from '@iotnxt-dev/reactcomponents';
import { IState, Role, Site } from '../interfaces';
import { Loading } from './loading';
import { useLightMode } from '../utils';

interface User {
    id: string;
    username: string;
    email: string;
    roleId: string[];
    siteId: string[];
    stateId: string[];
}

interface Props {}

interface State {
    users?: CloudAppResponse<User[]>;
    roles?: CloudAppResponse<Role[]>;
    sites?: CloudAppResponse<Site[]>;
    states?: CloudAppResponse<IState[]>;
    showInviteForm: boolean;
    inviteFormContent: User;
    response_inviteUser?: CloudAppResponse<unknown>;
}

export class UsersPage extends React.Component<Props, State> {
    state: State = {
        showInviteForm: false,
        inviteFormContent: {
            id: '',
            username: '',
            email: '',
            roleId: ['1'],
            siteId: ['1'],
            stateId: ['1'],
        },
        response_inviteUser: undefined,
    };

    componentDidMount(): void {
        this.getData();
        document.title = 'Conoco Phillips | Manage Users';
    }

    getData = async (): Promise<void> => {
        if (!api.token) throw new Error('token missing');

        const [users, roles, sites, states] = await Promise.all([
            api.User.GetAllUsers(),
            api.Role.GetAllRoles(),
            api.DropDowns.GetSites(),
            api.DropDowns.GetStates(),
        ]);

        this.setState({ users, roles, sites, states });
    };

    render(): JSX.Element {
        if (!api.token) throw new Error('token missing');
        if (!this.state.users || !this.state.roles || !this.state.sites || !this.state.states) return <Loading />;

        if (!this.state.users.data || !this.state.roles.data || !this.state.sites.data || !this.state.states.data)
            return <Loading />;

        return (
            <ComponentWrapper>
                <h1 style={{ marginLeft: 25 }}>Users</h1>
                <DynamicGrid
                    useLightMode={useLightMode()}
                    config={{ apiPath: '', authorization: '', server: '' }}
                    pageSize={15}
                    isLocked={false}
                    useHistory={false}
                    autoRefresh={false}
                    showAutoRefreshToggle={false}
                    jsonPropertiesOverride={{
                        columns: {
                            id: { displayName: 'Id' },
                            username: { displayName: 'Username' },
                            email: { displayName: 'Email' },
                            roleId: {
                                displayName: 'Roles',
                            },
                            siteId: {
                                displayName: 'Sites',
                            },
                            stateId: {
                                displayName: 'States',
                            },
                        },
                    }}
                    AddOrUpdateInstance={async (row) => {
                        if (row._additionalInfo) {
                            const additionalInfo = row._additionalInfo;

                            if (additionalInfo.insert) {
                                // invite user
                                await api.User.InviteNewCommanderUser({
                                    username: row.username,
                                    inviteEmail: row.email,
                                    firstName: row.username,
                                    lastName: row.lastName,
                                });
                            }
                            delete row._additionalInfo;
                        }

                        const user = row as User;
                        if (!user.roleId) user.roleId = [];
                        if (!user.siteId) user.siteId = [];
                        if (!user.stateId) user.stateId = [];

                        // Roles
                        user.roleId = user.roleId.map((roleText) => {
                            if (!this.state.roles || !this.state.roles.data) return roleText;
                            return this.state.roles.data.filter((r) => r.roleName === roleText)[0].id;
                        });
                        // Sites
                        user.siteId = user.siteId.map((siteText) => {
                            if (!this.state.sites || !this.state.sites.data) return siteText;
                            return this.state.sites.data.filter((s) => s.name === siteText)[0].id;
                        });
                        // States
                        user.stateId = user.stateId.map((stateText) => {
                            if (!this.state.states || !this.state.states.data) return stateText;
                            return this.state.states.data.filter((s) => s.name === stateText)[0].id;
                        });

                        const result = await api.User.UpsertUserDetail(user);
                        return result;
                    }}
                    conditionalOverrides={[
                        {
                            onColumnName: 'roleId',
                            component: (props) => {
                                if (!props) {
                                    return <div>error no props</div>;
                                }

                                const placeholder = props.row?.roleId?.join(', ') || '';
                                const selected: string[] = props.row?.data?.roleId || props.row?.roleId || [];

                                const options: IMultiSelectDataType[] =
                                    this.state.roles?.data?.map((entry) => ({
                                        value: entry.id,
                                        text: entry.roleName,
                                    })) || [];

                                return (
                                    <MultiSelect
                                        placeholder={placeholder}
                                        selected={selected}
                                        options={options}
                                        onChange={(e) => {
                                            props.onChange(e.selected);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            onColumnName: 'siteId',
                            component: (props) => {
                                if (!props) {
                                    return <div>error no props</div>;
                                }

                                const placeholder = props.row?.siteId?.join(', ') || '';
                                const selected: string[] = props.row?.data?.siteId || props.row?.siteId || [];

                                const options: IMultiSelectDataType[] =
                                    this.state.sites?.data?.map((entry) => ({
                                        value: entry.id,
                                        text: entry.name,
                                    })) || [];

                                return (
                                    <MultiSelect
                                        placeholder={placeholder}
                                        selected={selected}
                                        options={options}
                                        onChange={(e) => {
                                            props.onChange(e.selected);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            onColumnName: 'stateId',
                            component: (props) => {
                                if (!props) {
                                    return <div>error no props</div>;
                                }

                                const placeholder = props.row?.stateId?.join(', ') || '';
                                const selected: string[] = props.row?.data?.stateId || props.row?.stateId || [];

                                const options: IMultiSelectDataType[] =
                                    this.state.states?.data?.map((entry) => ({
                                        value: entry.id,
                                        text: entry.name,
                                    })) || [];

                                return (
                                    <MultiSelect
                                        placeholder={placeholder}
                                        selected={selected}
                                        options={options}
                                        onChange={(e) => {
                                            props.onChange(e.selected);
                                        }}
                                    />
                                );
                            },
                        },
                    ]}
                    getPagedInstances={async (pagingArgs) => {
                        const users = await api.User.GetAllUsers();
                        return applyFilteredPagingArgs(pagingArgs as FilteredArgs, users.data);
                    }}
                />
            </ComponentWrapper>
        );
    }
}
