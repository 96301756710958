import React, { useEffect, useState } from 'react';
import { Button } from './button';
import styled from 'styled-components';
import { EventEmitter } from 'events';

interface Props {
    data: MenuData[];
}

interface State {}

export interface MenuData {
    text?: string;
    name?: string;
    icon?: string;
    to?: string;
    nested?: MenuData[];
    direction?: Direction;
    onClick?: () => void;
}

type Direction = 'up' | 'down' | 'left' | 'right';

export class Menu extends React.Component<Props, State> {
    render(): JSX.Element {
        const menuEvents = new EventEmitter();

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {this.props.data.map((menuitem, index) => {
                    return (
                        <DropDownMenu
                            style={{ marginRight: 2, position: 'relative' }}
                            key={index}
                            {...menuitem}
                            // icon={menuitem.icon}
                            // text={menuitem.name}
                            // nested={menuitem.nested}
                            // direction={menuitem.direction}
                            onClick={menuitem.onClick}
                            menuEvents={menuEvents}
                        />
                    );
                })}
            </div>
        );
    }
}

const DropDownMenuList = styled.div`
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
`;

interface DropDownMenuProps {
    style: React.CSSProperties;
    direction?: Direction;
    onClick?: () => void;
    menuEvents: EventEmitter;
    setVisible?: (visible: boolean) => void;
    nested?: MenuData[];
}

function DropDownMenu(props: DropDownMenuProps): JSX.Element {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        // on mount...
        function handleMenuEvent() {
            setVisible(false);
        }

        props.menuEvents.on('closeall', handleMenuEvent);

        // component will unmount
        return () => {
            props.menuEvents.removeListener('closeall', handleMenuEvent);
        };
    });

    const menuEvents = new EventEmitter();

    const menuStyle: { [index: string]: unknown } = { background: '#d81a2b' };
    if (props.direction) {
        if (props.direction === 'right') {
            menuStyle.left = 200;
            menuStyle.top = 0;
        }
        if (props.direction === 'down') {
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <Button
                {...props}
                background="none"
                border="none"
                border-radius="none"
                style={{ width: '100%', textAlign: 'left' }}
                onClick={() => {
                    console.log('CLICK');
                    if (props.setVisible) {
                        props.setVisible(false);
                    }
                    props.menuEvents.emit('closeall');
                    if (props.nested) {
                        props.menuEvents.emit('closeall');
                        setVisible(!visible);
                    }
                    if (props.onClick) {
                        setVisible(false);
                        if (props.setVisible) {
                            props.setVisible(false);
                        }
                        props.onClick();
                    }
                }}
            />

            {visible && props.nested && (
                <DropDownMenuList style={menuStyle}>
                    {props.nested.map((menuitem, index) => {
                        return (
                            <DropDownMenu
                                icon={menuitem.icon ? menuitem.icon : 'fas fa-circle'}
                                {...menuitem}
                                // text={menuitem.name}
                                key={index}
                                style={{ width: 200, textAlign: 'left', position: 'relative' }}
                                nested={menuitem.nested}
                                direction={menuitem.direction}
                                onClick={menuitem.onClick}
                                menuEvents={menuEvents}
                                setVisible={(a: boolean) => {
                                    setVisible(a);
                                    if (props.setVisible) {
                                        props.setVisible(a);
                                    }
                                }}
                            />
                        );
                    })}
                </DropDownMenuList>
            )}
        </div>
    );
}
