import React from 'react';
import { api } from '../api';
import styled from 'styled-components';
import { CloudAppResponse } from '../shared/cloudappresponse';
import { Button } from './button';
import { CloudAppResponseDisplay, FormHeaderOptions, Header3, InputEdit, Table, TextButton } from './styledComponents';
import { isJson } from '../shared/isJson';
import { CloseButtonDiv } from './dropdownForm';
import { Tag } from '../interfaces';

const Panel = styled.div`
    background: #eee;
    flex: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    input {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 600;
    }
`;

interface State {
    response_uploadCsv?: CloudAppResponse<unknown>;
    response_validateCsv?: CloudAppResponse<Tag[]>;
    response_processFile?: CloudAppResponse<unknown>;
    response_discardCsvFile?: CloudAppResponse<unknown>;
    uploading: boolean;
    file?: File;
    busy?: boolean;
}

interface Props {}

export class TagsImportPage extends React.Component<Props, State> {
    state: State = {
        response_uploadCsv: undefined,
        response_validateCsv: undefined,
        response_processFile: undefined,
        response_discardCsvFile: undefined,
        uploading: false,
        file: undefined,
        busy: undefined,
    };

    uploadTimeout: NodeJS.Timeout | undefined;
    fileInput: HTMLInputElement | undefined;
    csvheader =
        'Site|Site Name|Site ID|Asset Type|States|Asset Serial Number|Asset Name|Status|Tag Number|Asset Location|Tag Type|Issue Type|Issue Date';

    componentDidMount(): void {
        document.title = 'Conoco Phillips | Tags Import';
    }

    uploadCsv({ target }: React.FormEvent<HTMLInputElement>): void {
        if (!api.token) throw new Error('token missing');
        const { files } = target as HTMLInputElement;

        this.setState({ uploading: true });
        const form = new FormData();

        if (!files) throw new Error('Invalid file');

        form.append('file', files[0]);

        const file: File = files[0];
        this.setState({ file });

        this.uploadTimeout = setTimeout(() => {
            if (this.fileInput) this.fileInput.value = '';
            this.setState({
                uploading: false,
                response_uploadCsv: {
                    isSuccessful: false,
                    exceptionMessage: 'Timeout',
                    message: '',
                },
            });
        }, 30000);

        fetch(api.apiUrl + '/api/File/UploadCsv', {
            headers: { Authorization: 'Bearer ' + api.token.access_token },
            method: 'post',
            body: form,
        })
            .then((res) => res.json())
            .then((response_uploadCsv: CloudAppResponse<unknown>) => {
                if (this.fileInput) this.fileInput.value = '';
                if (this.uploadTimeout) clearTimeout(this.uploadTimeout);

                if (response_uploadCsv.isSuccessful === false) {
                    this.processFile();
                }

                this.setState({ response_uploadCsv, uploading: false });
            })
            .catch((err) => {
                if (this.uploadTimeout) clearTimeout(this.uploadTimeout);
                console.log(err);
            });
    }

    validateCsv(): void {
        if (!api.token) throw new Error('token missing');

        this.setState({ busy: true });
        fetch(api.apiUrl + '/api/File/ValidateCsv', {
            method: 'post',
            headers: { Authorization: 'Bearer ' + api.token.access_token },
        })
            .then((res) => res.json())
            .then((response_validateCsv: CloudAppResponse<Tag[]>) => {
                if (response_validateCsv.isSuccessful === true) {
                    this.setState({ busy: false });
                }
                this.setState({ response_validateCsv, busy: false });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    processFile(): void {
        if (!api.token) throw new Error('token missing');

        this.setState({ response_processFile: undefined });

        fetch(api.apiUrl + '/api/File/ProcessFile', {
            method: 'post',
            headers: { Authorization: 'Bearer ' + api.token.access_token },
        })
            .then((res) => res.text())
            .then((response) => {
                if (isJson(response)) {
                    this.setState({ response_processFile: JSON.parse(response) });
                } else {
                    console.log(response);
                }
            })
            .catch((err) => {
                console.log('ProcessFile Error', err);
            });
    }

    discardCsvFile(): void {
        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/File/DiscardCsvFile', {
            method: 'delete',
            headers: { Authorization: 'Bearer ' + api.token.access_token },
        })
            .then((res) => res.text())
            .then((response) => {
                if (isJson(response)) {
                    this.setState({ response_discardCsvFile: JSON.parse(response) });
                }
            })
            .catch((err) => {
                console.log('DiscardCsvFile Error', err);
                // this.setState({ response_processFile: { isSuccessful: false, exceptionMessage: err, message: 'error' } })
            });
    }

    updateAndValidateRow(row: FileUploadModel, cb: (response: CloudAppResponse<Tag[]>) => void): void {
        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/File/UpdateAndValidateRow', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + api.token.access_token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(row),
        })
            .then((res) => res.text())
            .then((response) => {
                if (isJson(response)) {
                    cb(JSON.parse(response));
                }
            })
            .catch((err) => {
                console.log('DiscardCsvFile Error', err);
                // this.setState({ response_processFile: { isSuccessful: false, exceptionMessage: err, message: 'error' } })
            });
    }

    deleteRow(row: FileUploadModel, cb: (response: CloudAppResponse<Tag[]>) => void): void {
        if (!api.token) throw new Error('token missing');

        fetch(api.apiUrl + '/api/File/DeleteRow', {
            method: 'delete',
            headers: {
                Authorization: 'Bearer ' + api.token.access_token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(row),
        })
            .then((res) => res.text())
            .then((response) => {
                if (isJson(response)) {
                    cb(JSON.parse(response));
                }
            })
            .catch((err) => {
                console.log('DeleteRow Error', err);
            });
    }

    render(): JSX.Element {
        const csvheaderServerReturn =
            'Site|Site Name|Site ID|Asset Type|States|Asset Serial Number|Asset Name|Status|Tag Number|Asset Location|Tag Type|Issue Type|Issue Date';

        return (
            <div>
                <h1>Import Tags</h1>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: 'white',
                    }}
                >
                    <div style={{ padding: '20px' }}>
                        <div
                            style={{
                                border: '2px solid rgba(0,0,0,0.1)',
                                borderRadius: 2,
                            }}
                        >
                            <Panel style={{ background: 'none' }}>
                                <div style={{ padding: 0, display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ paddingTop: 0, flex: 1, padding: 20 }}>
                                        <Header3 style={{ marginTop: 0, padding: 0, marginBottom: 10 }}>
                                            Upload .csv
                                        </Header3>

                                        <input
                                            type="file"
                                            name="file"
                                            ref={(ref) => {
                                                if (ref) this.fileInput = ref;
                                            }}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                this.uploadCsv(e);
                                            }}
                                        />

                                        <CloudAppResponseDisplay text="Upload" data={this.state.response_uploadCsv} />

                                        {this.state.uploading && (
                                            <div
                                                style={{
                                                    height: 50,
                                                    padding: 10,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <div className="fa-2x">
                                                    <i className="fas fa-sync fa-spin" />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div style={{ background: '#eee', padding: 44, flex: 2 }}>
                                        <p>Example :</p>
                                        <pre>
                                            Site|Site Name|Site ID|Asset Type|States|Asset Serial Number|Asset
                                            Name|Status|Tag Number|Asset Location|Tag Type|Issue Type|Issue Date
                                            <br />
                                            NC|NC LD1|FH DU TOIT|AMSA|BATT HEAT|X16343|DU TOIT|WATER
                                            ASSET|11566310|FH|J3A|INITIAL|2020/02/07
                                            <br />
                                            NC|NC LD2|FH DU TOIT|AMSA|BATT HEAT|X61243|DU TOIT|GAS
                                            ASSET|11566310|FH|J3A|INITIAL|2020/02/07
                                            <br />
                                            NC|NC LD3|F DUBE|AMSA|BATT HEAT|X9838|DUBE|OXYGEN
                                            ASSET|11919763|F|J3A|INITIAL|2020/09/01
                                            <br />
                                            NC|NC LD4|FH DU TOIT|AMSA|BATT HEAT|X15243|DU TOIT|OIL
                                            ASSET|11565210|FH|J3A|INITIAL|2020/02/04
                                            <br />
                                        </pre>
                                        <div>
                                            <p>
                                                A .csv file can be exported from excel or from a plain text editor. The
                                                first row should have the column names as displayed below. Each row
                                                after that should have the exact same number of columns seperated by the
                                                pipe character.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {true && (
                                    <div style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                borderTop: '2px solid rgba(0,0,0,0.1)',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    padding: 20,
                                                    borderRight: '2px solid rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <Button
                                                    text="Validate Csv"
                                                    onClick={() => {
                                                        this.validateCsv();
                                                    }}
                                                    icon="fal fa-check"
                                                />
                                                <div style={{ paddingTop: 10 }}>
                                                    <CloudAppResponseDisplay
                                                        text="Validate"
                                                        data={this.state.response_validateCsv}
                                                    />
                                                </div>
                                                {this.state.busy && (
                                                    <div>
                                                        <i className="fas fa-circle-notch fa-spin" /> Busy
                                                    </div>
                                                )}
                                            </div>

                                            <div
                                                style={{
                                                    flex: 1,
                                                    padding: 20,
                                                    borderRight: '2px solid rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <Button
                                                    text="Process File"
                                                    onClick={() => {
                                                        this.processFile();
                                                    }}
                                                    icon="fal fa-cog"
                                                />
                                                <div style={{ paddingTop: 10 }}>
                                                    <CloudAppResponseDisplay
                                                        text="Process "
                                                        data={this.state.response_processFile}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    padding: 20,
                                                    borderRight: '2px solid rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <Button
                                                    text="Discard CSV File"
                                                    onClick={() => {
                                                        this.setState({
                                                            response_discardCsvFile: undefined,
                                                            response_processFile: undefined,
                                                            response_uploadCsv: undefined,
                                                            response_validateCsv: undefined,
                                                            uploading: false,
                                                            file: undefined,
                                                        });
                                                        this.discardCsvFile();
                                                    }}
                                                    icon="fal fa-times"
                                                />
                                                <div style={{ paddingTop: 10 }}>
                                                    <CloudAppResponseDisplay
                                                        text="Discard "
                                                        data={this.state.response_discardCsvFile}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.state.file && (
                                    <div
                                        style={{
                                            flex: 1,
                                            padding: 0,
                                            borderTop: '2px solid rgba(0,0,0,0.1)',
                                        }}
                                    >
                                        <div style={{ padding: 20 }}>
                                            <div>File Size : {this.state.file.size}</div>
                                            {this.state.response_validateCsv &&
                                                this.state.response_validateCsv.data && (
                                                    <div>Row count: {this.state.response_validateCsv.data.length}</div>
                                                )}
                                        </div>
                                    </div>
                                )}
                            </Panel>
                        </div>
                    </div>

                    {/* Table start */}
                    {this.state.response_validateCsv && this.state.response_validateCsv.data && (
                        <div style={{ padding: 0 }}>
                            <Panel>
                                <FormHeaderOptions>
                                    <div style={{ flex: 0, padding: 14, whiteSpace: 'nowrap' }}>Data Preview</div>

                                    <div style={{ flex: 0, padding: 14, whiteSpace: 'nowrap' }}>
                                        {true && <div></div>}
                                        {this.state.response_validateCsv.data.filter((tag) => tag.exceptions).length >
                                        0 ? (
                                            <div style={{ color: 'red' }}>
                                                {
                                                    this.state.response_validateCsv.data.filter((tag) => tag.exceptions)
                                                        .length
                                                }{' '}
                                                Errors
                                            </div>
                                        ) : (
                                            <div style={{ color: 'green' }}>No errors.</div>
                                        )}
                                    </div>

                                    <div style={{ flex: 1 }} />
                                    <div style={{ flex: 0, paddingTop: 7, whiteSpace: 'nowrap' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                }}
                                            >
                                                <CloudAppResponseDisplay
                                                    text="Process "
                                                    data={this.state.response_processFile}
                                                />
                                            </div>
                                            <Button
                                                text="Process File"
                                                onClick={() => {
                                                    this.processFile();
                                                }}
                                                style={{ marginLeft: 10, marginRight: 10 }}
                                                icon="fal fa-cog"
                                            />
                                        </div>
                                    </div>
                                    <CloseButtonDiv
                                        style={{ background: 'rgba(150,0,0,0.25)' }}
                                        onClick={() => {
                                            this.setState({
                                                response_discardCsvFile: undefined,
                                                response_processFile: undefined,
                                                response_uploadCsv: undefined,
                                                response_validateCsv: undefined,
                                                uploading: false,
                                                file: undefined,
                                            });
                                            this.discardCsvFile();
                                        }}
                                    >
                                        <i className="fal fa-times" />
                                    </CloseButtonDiv>
                                </FormHeaderOptions>

                                {/* {(this.state.response_processFile) && <div
                        style={{ color: 'green', padding: 50, textAlign: 'center', fontSize: 30 }}>
                        <i className='fal fa-check-circle' style={{ fontSize: 70 }} /> <br /> {this.state.response_processFile.message}
                    </div>} */}

                                {
                                    <div style={{ overflowX: 'scroll' }}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    {this.csvheader.split('|').map((heading, headingkey) => {
                                                        return <td key={headingkey}>{heading}</td>;
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.response_validateCsv.data.map((tag: Tag, tagkey) => {
                                                    const display = tag.dataString.split('|');

                                                    if (tag.exceptions !== '') {
                                                        const background = 'rgba(218,100,105,0.2)';
                                                        const styleTopRow = {
                                                            padding: '0 0px 0 0',
                                                            borderTop: '0px solid red',
                                                            borderBottom: 'none',
                                                            background,
                                                        };
                                                        const styleBottomRow = {
                                                            padding: '0 1px 0 0',
                                                            borderBottom: '0px solid red',
                                                            borderTop: 'none',
                                                            color: 'rgba(218,100,105,1)',
                                                            background,
                                                        };

                                                        if (tag.exceptions === 'Column Count is not correct') {
                                                            return (
                                                                <div key={`div-${tagkey}`}>
                                                                    <tr key={tagkey}>
                                                                        <td style={styleTopRow}>
                                                                            <i
                                                                                className="fas fa-exclamation-triangle"
                                                                                style={{
                                                                                    marginLeft: 5,
                                                                                    marginRight: 5,
                                                                                    fontSize: 14,
                                                                                    color: 'rgba(218,20,0,0.7)',
                                                                                }}
                                                                                title={tag.exceptions}
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                ...styleTopRow,
                                                                                ...{ padding: 2 },
                                                                            }}
                                                                            colSpan={14}
                                                                        >
                                                                            <input
                                                                                value={tag.dataString}
                                                                                style={{
                                                                                    width: '100%',
                                                                                    margin: '5px 0px',
                                                                                    fontSize: 11,
                                                                                }}
                                                                                onChange={({
                                                                                    target,
                                                                                }: React.FormEvent<HTMLInputElement>) => {
                                                                                    const { value } =
                                                                                        target as HTMLInputElement;
                                                                                    tag.dataString = value;
                                                                                    tag.changed = true;
                                                                                    this.setState((prev) => ({
                                                                                        ...prev,
                                                                                        response_validateCsv:
                                                                                            prev.response_validateCsv,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </td>

                                                                        <td style={styleTopRow}>
                                                                            {tag.changed && (
                                                                                <TextButton
                                                                                    style={{
                                                                                        marginRight: 5,
                                                                                        marginLeft: 5,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.updateAndValidateRow(
                                                                                            {
                                                                                                site: tag.dataString.split(
                                                                                                    '|',
                                                                                                )[0],
                                                                                                siteName:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[1],
                                                                                                siteId: tag.dataString.split(
                                                                                                    '|',
                                                                                                )[2],
                                                                                                assetType:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[3],
                                                                                                states: tag.dataString.split(
                                                                                                    '|',
                                                                                                )[4],
                                                                                                assetSerialNumber:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[5],
                                                                                                assetName:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[6],
                                                                                                status: tag.dataString.split(
                                                                                                    '|',
                                                                                                )[7],
                                                                                                tagNumber: parseInt(
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[8],
                                                                                                    10,
                                                                                                ),
                                                                                                assetLocation:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[9],
                                                                                                issueType:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[10],
                                                                                                issueDate:
                                                                                                    tag.dataString.split(
                                                                                                        '|',
                                                                                                    )[11],
                                                                                                dataString:
                                                                                                    tag.dataString,
                                                                                                lineNumber:
                                                                                                    tag.lineNumber,
                                                                                                id: tag.id,
                                                                                            },
                                                                                            (response) => {
                                                                                                if (
                                                                                                    response.data &&
                                                                                                    response.data[0]
                                                                                                ) {
                                                                                                    tag =
                                                                                                        response
                                                                                                            .data[0];
                                                                                                    this.setState({
                                                                                                        response_validateCsv:
                                                                                                            response,
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="fad fa-save" /> Save
                                                                                </TextButton>
                                                                            )}

                                                                            {!tag.changed && tag.exceptions && (
                                                                                <TextButton
                                                                                    style={{
                                                                                        marginRight: 5,
                                                                                        marginLeft: 5,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.deleteRow(
                                                                                            {
                                                                                                site: tag.site,
                                                                                                siteName: tag.siteName,
                                                                                                siteId: tag.siteId,
                                                                                                states: tag.states,
                                                                                                assetType:
                                                                                                    tag.assetType,
                                                                                                assetSerialNumber:
                                                                                                    tag.assetSerialNumber,
                                                                                                assetLocation:
                                                                                                    tag.assetLocation,
                                                                                                assetName:
                                                                                                    tag.assetName,
                                                                                                tagNumber:
                                                                                                    tag.tagNumber,
                                                                                                status: tag.status,
                                                                                                issueDate:
                                                                                                    tag.issueDate,
                                                                                                issueType:
                                                                                                    tag.issueType,
                                                                                                dataString:
                                                                                                    tag.dataString,
                                                                                                lineNumber:
                                                                                                    tag.lineNumber,
                                                                                                id: tag.id,
                                                                                                user: tag.user,
                                                                                            },
                                                                                            (response) => {
                                                                                                if (
                                                                                                    response.isSuccessful
                                                                                                ) {
                                                                                                    if (
                                                                                                        !this.state
                                                                                                            .response_validateCsv
                                                                                                    )
                                                                                                        return;
                                                                                                    if (
                                                                                                        !this.state
                                                                                                            .response_validateCsv
                                                                                                            .data
                                                                                                    )
                                                                                                        return;

                                                                                                    const response_validateCsv =
                                                                                                        this.state
                                                                                                            .response_validateCsv;
                                                                                                    if (
                                                                                                        response_validateCsv.data
                                                                                                    )
                                                                                                        response_validateCsv.data =
                                                                                                            response_validateCsv.data.filter(
                                                                                                                (o) =>
                                                                                                                    o !==
                                                                                                                    tag,
                                                                                                            );
                                                                                                    this.setState({
                                                                                                        response_validateCsv:
                                                                                                            this.state
                                                                                                                .response_validateCsv,
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="fad fa-trash" />{' '}
                                                                                    Delete
                                                                                </TextButton>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={tagkey + 'exceptionRow'}>
                                                                        <td colSpan={99} style={styleBottomRow}>
                                                                            <div
                                                                                style={{
                                                                                    padding: 5,
                                                                                    color: 'rgba(225,0,0,0.8)',
                                                                                }}
                                                                            >
                                                                                {tag.exceptions}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </div>
                                                            );
                                                        }

                                                        return (
                                                            <div key={`div-${tagkey}`}>
                                                                <tr key={tagkey + 'test'}>
                                                                    <td style={styleTopRow} key={'exceptionCol'}>
                                                                        {!tag.exceptions && (
                                                                            <i
                                                                                className="fas fa-exclamation-triangle"
                                                                                style={{
                                                                                    marginLeft: 5,
                                                                                    marginRight: 5,
                                                                                    fontSize: 14,
                                                                                    color: 'rgba(218,20,0,0.7)',
                                                                                }}
                                                                                title={tag.exceptions}
                                                                            />
                                                                        )}
                                                                    </td>

                                                                    {csvheaderServerReturn
                                                                        .split('|')
                                                                        .map((heading, headingKey) => {
                                                                            const value =
                                                                                tag.dataString.split('|')[headingKey];

                                                                            return (
                                                                                <td
                                                                                    style={{
                                                                                        ...styleTopRow,
                                                                                        ...{ padding: 2 },
                                                                                    }}
                                                                                    key={headingKey}
                                                                                >
                                                                                    <InputEdit
                                                                                        value={value}
                                                                                        onChange={({
                                                                                            target,
                                                                                        }: React.FormEvent<HTMLInputElement>) => {
                                                                                            const { value } =
                                                                                                target as HTMLInputElement;
                                                                                            let editedValue = value;
                                                                                            if (
                                                                                                heading.toLowerCase() ===
                                                                                                'tagnumber'
                                                                                            ) {
                                                                                                editedValue = parseInt(
                                                                                                    value,
                                                                                                    10,
                                                                                                ).toString();
                                                                                            }

                                                                                            const dataString =
                                                                                                tag.dataString.split(
                                                                                                    '|',
                                                                                                );

                                                                                            const index =
                                                                                                csvheaderServerReturn
                                                                                                    .split('|')
                                                                                                    .findIndex(
                                                                                                        (x) =>
                                                                                                            x ===
                                                                                                            heading,
                                                                                                    );
                                                                                            dataString[index] =
                                                                                                editedValue;

                                                                                            tag.dataString =
                                                                                                dataString.join('|');
                                                                                            tag.changed = true;

                                                                                            this.setState({
                                                                                                response_validateCsv:
                                                                                                    this.state
                                                                                                        .response_validateCsv,
                                                                                            });
                                                                                        }}
                                                                                        style={{
                                                                                            padding: '3px 7px',
                                                                                            fontSize: 11,
                                                                                            width: '100%',
                                                                                            border:
                                                                                                '1px solid ' +
                                                                                                background,
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    <td style={styleTopRow} key={tagkey + 'buttonCol'}>
                                                                        {tag.changed && (
                                                                            <TextButton
                                                                                style={{
                                                                                    marginRight: 5,
                                                                                    marginLeft: 5,
                                                                                }}
                                                                                onClick={() => {
                                                                                    this.updateAndValidateRow(
                                                                                        {
                                                                                            siteName: tag.siteName,
                                                                                            siteId: tag.siteId,
                                                                                            states: tag.states,
                                                                                            assetType: tag.assetType,
                                                                                            assetSerialNumber:
                                                                                                tag.assetSerialNumber,
                                                                                            assetLocation:
                                                                                                tag.assetLocation,
                                                                                            assetName: tag.assetName,
                                                                                            tagNumber: tag.tagNumber,
                                                                                            status: tag.status,
                                                                                            issueDate: tag.issueDate,
                                                                                            issueType: tag.issueType,
                                                                                            dataString: tag.dataString,
                                                                                            lineNumber: tag.lineNumber,
                                                                                            id: tag.id,
                                                                                            user: tag.user,
                                                                                        },
                                                                                        (response) => {
                                                                                            if (
                                                                                                response.data &&
                                                                                                response.data[0]
                                                                                            ) {
                                                                                                tag = response.data[0];
                                                                                                this.setState({
                                                                                                    response_validateCsv:
                                                                                                        response,
                                                                                                });
                                                                                            }
                                                                                        },
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fad fa-save" /> Save
                                                                            </TextButton>
                                                                        )}

                                                                        {!tag.changed && tag.exceptions && (
                                                                            <TextButton
                                                                                style={{
                                                                                    marginRight: 5,
                                                                                    marginLeft: 5,
                                                                                }}
                                                                                onClick={() => {
                                                                                    this.deleteRow(
                                                                                        {
                                                                                            siteName: tag.siteName,
                                                                                            siteId: tag.siteId,
                                                                                            states: tag.states,
                                                                                            assetType: tag.assetType,
                                                                                            assetSerialNumber:
                                                                                                tag.assetSerialNumber,
                                                                                            assetLocation:
                                                                                                tag.assetLocation,
                                                                                            assetName: tag.assetName,
                                                                                            tagNumber: tag.tagNumber,
                                                                                            status: tag.status,
                                                                                            issueDate: tag.issueDate,
                                                                                            issueType: tag.issueType,
                                                                                            dataString: tag.dataString,
                                                                                            lineNumber: tag.lineNumber,
                                                                                            id: tag.id,
                                                                                            user: tag.user,
                                                                                        },
                                                                                        (response) => {
                                                                                            if (response.isSuccessful) {
                                                                                                if (
                                                                                                    !this.state
                                                                                                        .response_validateCsv
                                                                                                )
                                                                                                    return;
                                                                                                if (
                                                                                                    !this.state
                                                                                                        .response_validateCsv
                                                                                                        .data
                                                                                                )
                                                                                                    return;

                                                                                                const response_validateCsv =
                                                                                                    this.state
                                                                                                        .response_validateCsv;
                                                                                                if (
                                                                                                    response_validateCsv.data
                                                                                                )
                                                                                                    response_validateCsv.data =
                                                                                                        response_validateCsv.data.filter(
                                                                                                            (o) =>
                                                                                                                o !==
                                                                                                                tag,
                                                                                                        );
                                                                                                this.setState({
                                                                                                    response_validateCsv:
                                                                                                        this.state
                                                                                                            .response_validateCsv,
                                                                                                });
                                                                                            }
                                                                                        },
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fad fa-trash" /> Delete
                                                                            </TextButton>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                {tag.exceptions && (
                                                                    <tr key={tagkey + 'exceptionRow'}>
                                                                        <td colSpan={99} style={styleBottomRow}>
                                                                            <div
                                                                                style={{
                                                                                    padding: 5,
                                                                                    color: 'rgba(225,0,0,0.8)',
                                                                                }}
                                                                            >
                                                                                {tag.exceptions}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <tr key={tagkey + 'asdf'}>
                                                                <td>
                                                                    {tag.exceptions && (
                                                                        <i
                                                                            className="fas fa-exclamation-triangle"
                                                                            style={{
                                                                                fontSize: 14,
                                                                                color: 'rgba(218,20,0,0.7)',
                                                                            }}
                                                                            title={tag.exceptions}
                                                                        />
                                                                    )}
                                                                </td>

                                                                {this.csvheader.split('|').map((heading, j) => {
                                                                    return <td key={j + 'col'}>{display[j]}</td>;
                                                                })}
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </Panel>
                        </div>
                    )}

                    {/* Table end */}
                </div>
            </div>
        );
    }
}

type DataItemStateinteger = number; // 0,1,2
type DateString = string;

export interface FileUploadModel {
    dataItemState?: DataItemStateinteger;
    user?: string;
    site?: string;
    //shift?: string
    siteName?: string;
    siteId?: string;
    states?: string;
    assetType?: string;
    assetSerialNumber?: string;
    assetLocation?: string;
    assetName?: string;
    tagNumber?: number;
    status?: string;
    issueDate?: DateString;
    issueType?: string;
    exceptions?: string;
    dataString?: string;
    lineNumber: number;
    id?: string;
}
