import React from 'react';

interface Props {
    text?: string;
    color?: string;
}

export const Loading: React.FC<Props> = (props: Props): JSX.Element => {
    const style = { color: props.color || 'rgba(125,125,125,0.75)' };
    return (
        <div style={{ textAlign: 'center' }}>
            <i className="fas fa-circle-notch fa-spin" style={style} />{' '}
            {props.text && <div style={style}>{props.text}</div>}
        </div>
    );
};
