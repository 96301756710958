import React from 'react';
import styled from 'styled-components';
import { Button } from './button';
import { CloudAppResponse } from '../shared/cloudappresponse';

interface Props {
    addClick: () => void;
    itemsClick: () => void;
    items?: unknown[];
    title: string;
    icon: string;
    response?: CloudAppResponse<unknown>;
}

interface State {}

const DropdownsBlockDiv = styled.div`
    background: white;
    padding: 10px;
    margin: 10px;
    border-radius: 2px;
    flex: 1;
    box-shadow: 0px 10px 12px -5px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(0, 0, 0, 0.05);

    .title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .icon {
        text-align: center;
        padding: 20px;
    }

    .nav {
        display: flex;
        flex-direction: row;
    }
`;

export class DropdownsBlock extends React.Component<Props, State> {
    render(): JSX.Element {
        if (!this.props.items) return <div></div>;
        return (
            <DropdownsBlockDiv>
                <div className="title">{this.props.title} </div>
                <div className="icon">
                    <i className={this.props.icon} />
                </div>
                <div className="nav">
                    <div>
                        <Button
                            background="none"
                            color="rgba(0,0,0,0.8)"
                            text="Add"
                            icon="fal fa-edit"
                            onClick={this.props.addClick}
                        />
                    </div>
                    <div style={{ flex: 1 }} />
                    <div>
                        {this.props.items ? (
                            <Button
                                background="none"
                                color="rgba(0,0,0,0.8)"
                                onClick={this.props.itemsClick}
                                text={this.props.items.length + ' items'}
                            />
                        ) : (
                            <>
                                <Button
                                    text={this.props.response ? this.props.response.message : 'loading'}
                                    background="none"
                                    color="rgba(0,0,0,0.8)"
                                    icon={this.props.response ? 'fas fa-times' : 'fas fa-circle-notch fa-spin'}
                                />
                            </>
                        )}
                    </div>
                </div>
            </DropdownsBlockDiv>
        );
    }
}
