import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ComponentWrapper, StyledButton } from '@iotnxt-dev/reactcomponents';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface State {
    numPages?: number;
    pageNumber: number;
    width: number;
    filename: string;
}

export class HowToUsePage extends React.Component {
    state: State = {
        numPages: undefined,
        pageNumber: 1,
        width: 100,
        filename: '/manual_conoco.pdf',
    };

    rtime: Date | undefined;
    timeout: NodeJS.Timeout | undefined;
    delta = 200;

    el: HTMLDivElement | undefined;

    componentDidMount(): void {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.resize);
        if (this.timeout) clearTimeout(this.timeout);
        document.title = 'Conoco Phillips | How to use';
    }

    resize = (): void => {
        this.rtime = new Date();
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.resizeEnd, 200);
    };

    resizeEnd = (): void => {
        console.log('Resize End run..');
        console.log('updateSize');
        if (this.el)
            if (this.state.width !== this.el.clientWidth) {
                this.setState({ width: this.el.clientWidth });
            }
    };

    render(): JSX.Element {
        const { pageNumber, numPages } = this.state;

        return (
            <ComponentWrapper
                style={{ padding: 0, width: 500 }}
                ref={(el) => {
                    if (el) this.el = el;
                    this.resize();
                }}
            >
                <div style={{ overflow: 'hidden', width: 500, height: 700 }}>
                    <Document
                        file={this.state.filename}
                        onLoadSuccess={(pdf) => {
                            this.setState({ numPages: pdf.numPages });
                        }}
                    >
                        <Page pageNumber={pageNumber} width={this.state.width} />
                    </Document>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', padding: 10, paddingTop: 15 }}>
                    <div>
                        <StyledButton
                            disabled={pageNumber == 1}
                            onClick={() => {
                                if (pageNumber > 1) {
                                    this.setState({ pageNumber: this.state.pageNumber - 1 });
                                }
                            }}
                        >
                            <i className={'fa fa-arrow-left'} /> prev
                        </StyledButton>
                    </div>
                    <div>
                        <StyledButton
                            disabled={pageNumber == (numPages || 0)}
                            onClick={() => {
                                if (!numPages) return;

                                if (pageNumber < numPages) {
                                    this.setState({ pageNumber: this.state.pageNumber + 1 });
                                }
                            }}
                        >
                            <i className={'fa fa-arrow-right'} /> next
                        </StyledButton>
                    </div>
                    <div style={{ padding: 10 }}>
                        Page {pageNumber} of {numPages}
                    </div>
                </div>

                {/* <h2 style={{ fontWeight: 600, color: 'white', marginTop: 50 }}>Download</h2> */}
                <div style={{ marginBottom: 50, padding: 15 }}>
                    <a href={this.state.filename} style={{ color: 'white' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 300,
                                background: 'rgba(255,255,255,0.2)',
                                padding: 10,
                                borderRadius: 5,
                            }}
                        >
                            <div>
                                <i className="fas fa-file-pdf" style={{ color: 'white', fontSize: 30 }} />
                            </div>
                            <div style={{ paddingTop: 7, paddingLeft: 12 }}>{this.state.filename}</div>
                        </div>
                    </a>
                </div>
            </ComponentWrapper>
        );
    }
}
