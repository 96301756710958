import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface Props {
    text?: string;
    icon?: string;
    spot?: boolean;
    altStyle?: boolean;
    altLighter?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
    disabled?: boolean;
    className?: string;
    roundimg?: string;
    to?: string; // router link
    href?: string; // hard link
    /** for showing triangle */
    nested?: unknown[];
    direction?: 'up' | 'down' | 'right' | 'left';

    [index: string]: unknown;
}

interface ButtonStyleProps {
    spot?: boolean;
    altStyle?: boolean;
    altLighter?: boolean;
    border?: string;
    background?: string;
    focusColor?: string;
    'border-radius'?: string;

    [index: string]: unknown;
}

const ButtonStyled = styled.button<ButtonStyleProps>`
    background: ${(props) => (props.background ? props.background : props.theme.buttonBackground)};
    margin: 0px;
    padding: ${({ theme }) => theme.padding} ${({ theme }) => parseInt(theme.padding, 10) * 2};
    font-size: 15px;
    font-weight: 600;
    color: ${(props) => (props.color ? props.color : 'white')};

    white-space: nowrap;
    transition: all 0.1s linear;
    border: ${(props) => (props.border ? props.border : '2px solid ' + props.theme.inputBorder)};
    outline: 0;
    display: flex;
    flex-direction: row;

    border-radius: ${(props) => (props['border-radius'] ? props['border-radius'] : '4px')};

    :hover {
        color: white;
        background: ${({ theme }) => theme.buttonBackgroundHover};
        cursor: pointer;
    }

    :focus {
        outline: 0;
        border: ${(props) => (props.border ? props.focusColor : '2px solid props.theme.inputBorder')};
        color: 2px solid ${({ theme }) => theme.focusColor};
    }

    div.roundimg {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
    }

    div.buttonText {
        flex: 1;
        padding: ${({ theme }) => theme.padding};
    }
`;

interface State {
    text: string;
    link: boolean;
}

export class Button extends React.Component<Props, State> {
    state = {
        text: 'Default',
        link: false,
    };

    static getDerivedStateFromProps(props: Props): Props {
        return props;
    }

    renderButton(): JSX.Element {
        //   if (this.state.link && this.props.to) {
        //     return <Redirect to={this.props.to} />
        // }

        let className = 'button';
        if (this.props.className) {
            className += ' ' + this.props.className;
        }
        return (
            <ButtonStyled
                {...this.props}
                onClick={() => {
                    if (this.props.onClick) this.props.onClick();

                    if (this.props.href) window.location.replace(this.props.href);
                }}
                className={className}
                altStyle={this.props.altStyle}
                altLighter={this.props.altLighter}
                spot={this.props.spot}
            >
                {this.props.roundimg && (
                    <div className="roundimg">
                        <img src={this.props.roundimg} style={{ width: '100%' }} alt="" />
                    </div>
                )}

                <div className="buttonText">
                    <i className={'' + this.props.icon ? this.props.icon : ''} /> {this.props.children}{' '}
                    {this.props.text}
                </div>

                {this.props.nested && (
                    <div style={{ paddingTop: 4 }}>
                        {this.props.direction === 'down' && (
                            <i className={'fal fa-angle-down'} style={{ fontSize: 12 }} />
                        )}
                        {this.props.direction === 'right' && (
                            <i className={'fal fa-angle-right'} style={{ fontSize: 12 }} />
                        )}
                        {this.props.direction === 'up' && <i className={'fal fa-angle-up'} style={{ fontSize: 12 }} />}
                        {this.props.direction === 'left' && (
                            <i className={'fal fa-angle-left'} style={{ fontSize: 12 }} />
                        )}
                    </div>
                )}
            </ButtonStyled>
        );
    }

    render(): JSX.Element {
        if (this.props.to) {
            return <Link to={this.props.to}>{this.renderButton()}</Link>;
        } else {
            return this.renderButton();
        }
    }
}

// https://polished.js.org/docs/
