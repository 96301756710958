import React from 'react';
import { api } from '../api';
import { CloudAppResponse } from '../shared/cloudappresponse';
import { DropdownForm } from './dropdownForm';
import { AssetForm, assetFormType } from './assetForm';
import { URLSearchParamsObj } from '../utils/urlsearchparams';
import { URLSearchProps } from '../App';
import { Asset } from '../interfaces';
import { ComponentWrapper, DynamicGrid, JsonPropertiesResponse, StyledButton } from '@iotnxt-dev/reactcomponents';
import { Loading } from './loading';
import {
    colouredStatus,
    FormatDateComponent,
    overrideAssetSN,
    overrideSite,
    overrideStatus,
    overrideStatusDate,
} from './gridOverrides';
import { GridConfigType } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/interfaces';
import { API as DynamicGridAPI } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/api';
import { useLightMode } from '../utils';

interface State {
    assets?: Asset[];
    response_getAssets?: CloudAppResponse<Asset[]>;
    showAddAssetForm: boolean;
    assetForm?: assetFormType;
    response_addAssetForm?: CloudAppResponse<CloudAppResponseFormValidation>;
    search?: string;
    searchtype?: string;
    selectedSites: string[];
    jsonProperties?: JsonPropertiesResponse;
}

interface AssetComponentProps {
    site?: string;
}

export interface CloudAppResponseFormValidation {
    errors: { [index: string]: string[] };
    status: number;
    title: string;
}

export class AssetComponent extends React.Component<AssetComponentProps, State> {
    state: State = {
        assets: undefined,
        response_getAssets: undefined,
        showAddAssetForm: false,
        assetForm: undefined,
        /** error response on add asset form */
        response_addAssetForm: undefined,

        /** search */
        search: undefined, // on search apply
        searchtype: undefined, // as user types
        selectedSites: [],
    };

    config?: GridConfigType;

    componentDidMount = async (): Promise<void> => {
        this.getData();
        document.title = 'Conoco Phillips | Assets';

        // handle url query params. Searching by site using query url.
        const selectedSite = URLSearchParamsObj<URLSearchProps>().site;
        if (selectedSite)
            this.setState({
                selectedSites: [selectedSite],
            });

        // ----------------------------------------
        // Get GetJsonProperties
        // We get the jsonproperties column data, then override status and assetType enum values
        if (!api || !api.token) {
            throw new Error('missing api or token');
        }
        this.config = {
            server: '',
            apiPath: '/api/AssetDynamicGrid',
            authorization: 'Bearer ' + api.token.access_token,
        };
        const dynamicGridApi = new DynamicGridAPI();
        dynamicGridApi.config = this.config;
        dynamicGridApi.headers.authorization = this.config.authorization;

        const [statuses, assetTypes, getJsonProperties] = await Promise.all([
            api.DropDowns.GetStatuses(),
            api.DropDowns.GetAssetTypes(),
            dynamicGridApi.GetJsonProperties(),
        ]);
        const jsonProperties = getJsonProperties.data;

        if (statuses.data && assetTypes.data && jsonProperties) {
            // override GetJsonProperties status and assetType
            jsonProperties.columns['status'].values = statuses.data.map((s) => s.name);
            jsonProperties.columns['status'].type = 'enum';
            jsonProperties.columns['assetType'].values = assetTypes.data.map((a) => a.name);
            jsonProperties.columns['assetType'].type = 'enum';
            this.setState({ jsonProperties });
        }
        // ----------------------------------------
    };

    async getData(): Promise<void> {
        if (!api.token) throw new Error('Missing api token');
        const response_getAssets = await api.Asset.GetAssets();
        if (response_getAssets) this.setState({ response_getAssets });
    }

    assetSearch = (asset: Asset | { [index: string]: string }): Asset | void => {
        if (this.state.selectedSites.length > 0) {
            if (this.state.selectedSites.indexOf(asset.assetSiNumber) === -1) {
                return;
            }
        }

        if (this.state.search !== '' && this.state.search !== undefined) {
            const propnames = Object.keys(asset);
            for (const propname of propnames) {
                try {
                    if (
                        (asset as Record<string, string>)[propname]
                            .toLowerCase()
                            .indexOf(this.state.search.toLowerCase()) >= 0
                    ) {
                        return asset as Asset;
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        } else {
            return asset as Asset;
        }
    };

    render(): JSX.Element {
        if (!api.token) return <Loading />;
        if (!this.state.jsonProperties) return <Loading text={'Loading Columns'} />;
        // this is the url query params.. like ex: ?fullscreen=false&site=VDB
        const searchparams = URLSearchParamsObj<URLSearchProps>();

        let showHeading = true;

        if (searchparams.fullscreen === 'true') showHeading = false;
        delete searchparams.fullscreen;
        return (
            <ComponentWrapper>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {showHeading && <h1 style={{ marginLeft: 10 }}>Assets</h1>}
                    <div style={{ flex: 1 }} />
                    <div>
                        <StyledButton
                            style={{ marginRight: 20, marginTop: showHeading ? 40 : 0 }}
                            onClick={() => {
                                this.setState({
                                    showAddAssetForm: true,
                                    response_addAssetForm: undefined,
                                });
                            }}
                        >
                            <i className="fal fa-plus" /> Add Asset
                        </StyledButton>
                    </div>
                </div>

                <DynamicGrid
                    useLightMode={useLightMode()}
                    key={'alltags'}
                    allowEdits={false}
                    useHistory={false}
                    isLocked
                    useImport={false}
                    useExport={true}
                    pageSize={15}
                    orderByColumn="statusDate"
                    orderByColumnSorting="DESC"
                    searchColumnData={searchparams}
                    showAutoRefreshToggle={true}
                    autoRefresh={true}
                    jsonPropertiesOverride={this.state.jsonProperties}
                    conditionalOverrides={[
                        {
                            onColumnName: 'lastModifiedDate',
                            component: FormatDateComponent,
                        },
                        overrideAssetSN,
                        overrideSite,
                        overrideStatusDate,
                        overrideStatus,
                    ]}
                    config={this.config}
                    conditionalRowStyle={colouredStatus}
                />

                {this.renderAssetAddAssetForm()}
            </ComponentWrapper>
        );
    }

    renderAssetAddAssetForm = (): JSX.Element => {
        if (!this.state.showAddAssetForm) return <></>;
        return (
            <DropdownForm
                title="Commission a new Asset"
                apply={async () => {
                    if (!this.state.assetForm) throw new Error('Form can not be empty.'); // empty form?
                    if (!this.state.assetForm.assetSiNumber) throw new Error('Asset Si Number can not be empty!'); // empty form?
                    this.state.assetForm.assetSiNumber = this.state.assetForm.assetSiNumber.trim();
                    if (['', 'na', 'n/a', 'n\\a'].includes(this.state.assetForm.assetSiNumber.toLowerCase())) {
                        throw new Error('Not a valid Asset Si Number.');
                    }

                    // Before applying we first request asset with this assetSiNumber to check if it already exists.
                    const checkAssetResponse = await api.Asset.CheckAsset(this.state.assetForm.assetSiNumber);

                    if (checkAssetResponse.isSuccessful && checkAssetResponse.data === true) {
                        throw new Error('Asset with this assetSiNumber already exists!');
                    }

                    if (checkAssetResponse.data !== false) {
                        throw new Error('Unexpected error occured while checking for duplicate asset.');
                    }

                    // If nothing threw an error we are all good to add the new asset:
                    const response = await api.Asset.UpsertAsset(this.state.assetForm);

                    if (!response.isSuccessful) {
                        throw new Error(response.exceptionMessage);
                    }

                    if (response.isSuccessful) {
                        this.setState({
                            showAddAssetForm: false,
                        });
                        this.getData();
                    }
                }}
                applyButtonIcon="fal fa-user-plus"
                applyButtonText="Add Asset"
                close={() => {
                    this.setState({ showAddAssetForm: false });
                }}
            >
                <AssetForm
                    response={this.state.response_addAssetForm && this.state.response_addAssetForm.data}
                    formChange={(assetForm: assetFormType) => {
                        this.setState({ assetForm });
                    }}
                />
            </DropdownForm>
        );
    };
}
