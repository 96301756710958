import styled, { createGlobalStyle, css, ThemeDefinition } from 'styled-components';

/** ThemeDefinition interface is defined in in types_styled.d.ts so theme autocompletes inside css */

export const gijimaTheme: ThemeDefinition = {
    name: 'light',
    body: '#1d2029',
    bodyAlt: '#E9ECEF',
    bodyAltLighter: '#F8F8F8',
    text: '#ccc',
    toggleBorder: '#f00',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
    bgSpot: '#D4D4D4',
    brandSpot: '#c3272b',
    focusColor: '#343539',
    alarmColor: '#DF3939',
    radius: '4px',
    padding: '4px',
    inputBorder: 'rgba(0,0,0,0.05)',
    widgetBackground: 'white',
    widgetBorder: '1px solid #E4E6E7',
    widgetMenuShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.05)',
    buttonBackground: '#0d3859',
    buttonBackgroundHover: '#205577',
    buttonText: 'white',
};

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  #app {
    overflow: visible;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.focusColor};
    transition: all 0.1s linear;
  }

  a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.brandSpot};
  }

  body {
    background: ${({ theme }) => theme.body};
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    margin: 0;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
  }
  
  h1 {
    font-weight: 600;
    color: white;
  }

  p { 
    font-size: 12px;
    margin: 0;
    padding:0;
    margin-bottom: 10px;
  }

  pre {
    font-family: monospace;
    font-size: 10px;
    color: #333;
    background: rgba(0,0,0,0.1);
    padding: 10px;    
  }

  `;
export const Container = styled.div`
    padding: 0px;
`;

interface PageProps {
    fullscreen?: 'true' | 'false';
}

export const Page = styled.div<PageProps>`
    background: #0d3859;
    min-height: 400px;
    padding: 20px;

    ${(props) =>
        props.fullscreen === 'true' &&
        css`
            padding: 0;
            height: 100%;
        `}
`;
