import * as React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from './utils/history';
import { api } from './api';
import { ThemeProvider } from 'styled-components';
import { gijimaTheme as oldthemelegacy, GlobalStyles, Page } from './theme';
import { NavBar } from './components/navbar';
import { Footer } from './components/footer';
import { Welcome } from './components/welcome';
import { DropdownsPage } from './components/dropdowns';
import { UsersPage } from './components/users_page';
import { RolesPage } from './components/roles_page';
import { PermissionsPage } from './components/permissions_page';
import { HowToUsePage } from './components/howtouse';
import { Dashboard } from './components/dashboard';
import { URLSearchParamsObj } from './utils/urlsearchparams';
import { AssetComponent } from './components/assets';
import { AllTags } from './components/tags_alltags';
import { TagsImportPage } from './components/tags_import_page';
import { AssetPage } from './components/assetPage';
import { IoTnxtAPI } from '@iotnxt-dev/api';
import { CloudAppResponse } from './shared/cloudappresponse';
import { AppSettings } from './shared/appsettings';
import { SitesMain } from './components/sitesMain';
import { Loading } from './components/loading';

import {
    CloudBaseResponse,
    CustomThemeProvider,
    GetThemes,
    Theme,
    ThemesResultType,
} from '@iotnxt-dev/reactcomponents';
import { IoTTokenDecoded } from '@iotnxt-dev/shared';

interface Props {}

interface State {
    loggedin: boolean;
    themeResponse?: CloudBaseResponse<ThemesResultType>;
}

export interface URLSearchProps {
    /** in url put ?fullscreen=true to hide the header and footer */
    fullscreen?: 'true' | 'false';
    site?: string;
}

class App extends React.Component<Props, State> {
    state: State = {
        themeResponse: undefined,
        loggedin: false,
    };

    authApi?: IoTnxtAPI;

    renewToken?: NodeJS.Timeout;

    componentDidMount(): void {
        this.doAuth();

        this.renewToken = setInterval(() => {
            this.authApi?.renewToken();
        }, 1000 * 60 * 60 * 12);
    }

    componentWillUnmount(): void {
        if (this.renewToken) clearInterval(this.renewToken);
    }

    doAuth = async (): Promise<void> => {
        const appSettings: CloudAppResponse<AppSettings> = await fetch('/api/AppSettings/Get').then((res) =>
            res.json(),
        );
        if (!appSettings || !appSettings.data) throw new Error('could not load settings');

        this.authApi = new IoTnxtAPI({
            authentication: {
                scope: 'permissions api',
                client_id: appSettings.data.client_id,
                server: appSettings.data.commanderUrl,
            },
        });

        const token = await this.authApi.auth();
        // check decoded token points to correct server.
        if (this.authApi && this.authApi.account) {
            const account = this.authApi.account;
            if (account.iss.indexOf(appSettings.data.commanderUrl) !== 0) {
                console.log('ISS mismatch! Renewing token', account.iss, appSettings.data.commanderUrl);
                this.authApi.renewToken();
                return;
            }

            if (appSettings.data.client_id !== account.client_id) {
                console.log('client_id mismatch! Renewing token', appSettings.data.client_id, account.client_id);
                this.authApi.renewToken();
                return;
            }
        }
        // end check token

        api.apiUrl = appSettings.data.apiUrl;
        api.commanderUrl = appSettings.data.commanderUrl;
        api.client_id = appSettings.data.client_id;
        api.account = this.authApi.account as IoTTokenDecoded;

        if (token) {
            api.token = token;

            const themeResponse = await GetThemes(
                '/api/DashboardDynamicGrid/GetThemeProperties',
                `${api.token.token_type} ${api.token.access_token}`,
            ).catch((err) => {
                console.log(err);
            });

            if (themeResponse && themeResponse.data && themeResponse.data.themes[themeResponse.data.activeThemeName]) {
                api.theme = themeResponse.data.themes[themeResponse.data.activeThemeName];
                this.setState({ themeResponse });
            }
            this.setState({});
        }
    };

    navbar(): JSX.Element {
        const preferred_username = ''; // api.account.preferred_username

        return (
            <NavBar
                menu={[
                    {
                        text: 'Dashboard',
                        icon: 'fal fa-desktop',
                        to: '/dashboard',
                    },
                    {
                        text: 'Tags',
                        icon: 'fal fa-tags',
                        direction: 'down',
                        nested: [
                            {
                                text: 'Import Tags',
                                to: '/import-tags',
                                icon: 'fal fa-file-import',
                            },
                            {
                                text: 'All Tags',
                                to: '/all-tags',
                                icon: 'fal fa-th-list',
                            },
                        ],
                    },
                    {
                        text: 'Assets',
                        to: '/assets',
                        icon: 'fal fa-sensor',
                    },
                    {
                        text: 'Sites',
                        to: '/sites',
                        icon: 'fal fa-map-marker-alt',
                    },
                    {
                        text: 'Set Up Dropdowns',
                        to: '/dropdowns',
                        icon: 'fal fa-cog',
                    },
                    {
                        text: 'Users & Roles',
                        icon: 'fal fa-user',
                        direction: 'down',
                        nested: [
                            {
                                text: 'Manage Users',
                                to: '/users',
                                icon: 'fal fa-users-cog',
                            },
                            {
                                text: 'Manage Roles',
                                to: '/manage-roles',
                                icon: 'fal fa-users-class',
                            },
                            {
                                text: 'Manage Permissions',
                                to: '/permissions',
                                icon: 'fal fa-user-shield',
                            },
                        ],
                    },
                    {
                        text: 'Help',
                        icon: 'fal fa-question-circle',
                        direction: 'down',
                        nested: [
                            {
                                text: 'How to Use',
                                icon: 'fal fa-book',
                                to: '/how-to-use',
                            },
                            {
                                text: 'About',
                                icon: 'fal fa-info-circle',
                                to: '/about',
                            },
                        ],
                    },
                    {
                        text: preferred_username,
                        icon: 'fal fa-user',
                        direction: 'down',
                        nested: [
                            {
                                text: 'Logout',
                                icon: 'fal fa-sign-out',
                                onClick: () => {
                                    api.account = undefined;
                                    api.token = undefined;

                                    if (api.commanderUrl) window.location.replace(api.commanderUrl);
                                },
                            },
                        ],
                    },
                ]}
            />
        );
    }

    render(): JSX.Element | null {
        if (!api.account || !this.authApi || !this.authApi.account)
            return (
                <div style={{ marginTop: 100, fontSize: 30 }}>
                    <Loading />
                </div>
            );

        const searchParams = URLSearchParamsObj<URLSearchProps>();

        let showNavbar = true;
        let showFooter = true;
        if (searchParams.fullscreen === 'true') {
            showNavbar = false;
            showFooter = false;
        }

        // Use the active theme if possible
        const theme =
            this.state.themeResponse &&
            this.state.themeResponse.data.themes[this.state.themeResponse.data.activeThemeName]
                ? this.state.themeResponse.data.themes[this.state.themeResponse.data.activeThemeName]
                : Theme.defaultThemeData.themes['default'];

        if (api.account)
            return (
                <div>
                    <ThemeProvider theme={oldthemelegacy}>
                        <GlobalStyles />
                        <Router history={history}>
                            {showNavbar && this.navbar()}

                            <Switch>
                                <Page fullscreen={searchParams.fullscreen}>
                                    <Route exact path="/">
                                        <Welcome />
                                    </Route>

                                    <Route exact path="/dashboard">
                                        <CustomThemeProvider theme={theme}>
                                            <Dashboard />
                                        </CustomThemeProvider>
                                    </Route>

                                    <Route exact path="/import-tags">
                                        <TagsImportPage />
                                    </Route>

                                    <Route exact path="/export-tags">
                                        <h1>Export Tags</h1>
                                    </Route>

                                    <Route exact path="/dropdowns">
                                        <DropdownsPage />
                                    </Route>

                                    {/* Migrated components from @iotnxt-dev/reactcomponents with propertheme */}
                                    <CustomThemeProvider theme={theme}>
                                        <Route exact path="/assets/:assetSiNumber" component={AssetPage} />

                                        <Route exact path="/sites" component={SitesMain} />
                                        {/* <Route exact path="/sites/:name" component={SitesMain} /> */}
                                        <Route exact path="/assets">
                                            <AssetComponent />
                                        </Route>

                                        <Route exact path="/all-tags">
                                            <AllTags />
                                        </Route>

                                        <Route exact path="/users">
                                            <UsersPage />
                                        </Route>

                                        <Route exact path="/how-to-use">
                                            <h1>How to use</h1>
                                            <HowToUsePage />
                                        </Route>
                                    </CustomThemeProvider>

                                    <Route exact path="/manage-roles">
                                        <h1>Manage Roles</h1>
                                        <RolesPage />
                                    </Route>

                                    <Route exact path="/permissions">
                                        <h1>Manage Permissions</h1>
                                        <PermissionsPage />
                                    </Route>

                                    <Route exact path="/about">
                                        <h1>About</h1>
                                        <div style={{ color: 'white' }}>
                                            <p>Developed for Conoco Phillips by IoT.nxt</p>

                                            <p>Authors:</p>
                                            <ul style={{ fontSize: 12 }}>
                                                <li>Christo Kruger (Dev Manager) - email: christo.kruger@iotnxt.com</li>
                                                <li>
                                                    Sabir Behera (Solutions Architect Lead) - sabir.behera@iotnxt.com
                                                </li>
                                                <li>
                                                    Luc van der Walt (Project Manager) - email:
                                                    luc.vanderwalt@iotnxt.com
                                                </li>
                                                <li>
                                                    Selebalo Setenane (Front-End Dev) - email:
                                                    selebalo.setenane@iotnxt.com
                                                </li>
                                                <li>
                                                    Christopher Nchabeleng (Back-End Dev) - email:
                                                    christopher.nchabeleng@iotnxt.com
                                                </li>
                                            </ul>
                                        </div>
                                    </Route>

                                    <Route exact path="/logout">
                                        <h1>Logout</h1>
                                    </Route>
                                </Page>
                            </Switch>
                            {showFooter && <Footer />}
                        </Router>
                    </ThemeProvider>
                </div>
            );

        return null;
    }
}

export default App;
