/** parses url query params */
export const URLSearchParamsObj = <T>(): T => {
    const urlParams = new URLSearchParams(window.location.search);
    const params: Record<string, unknown> = {};

    urlParams.forEach((value, key) => {
        params[key] = value;
    });
    return params as T;
};
