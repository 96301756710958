import React from 'react';
import { api } from '../api';
import { Link } from 'react-router-dom';
import { encodeQueryData } from '../shared/encodeQueryData';
import { CloudAppResponse } from '../shared/cloudappresponse';
import { Loading } from './loading';
import { Button } from './button';
import { AssetForm, assetFormType } from './assetForm';
import { JSONpropToCamelCase } from '../shared/shared';
import { CloudAppResponseDisplay } from './styledComponents';
import { Asset, AssetStatusHistory, IState, TagStatus } from '../interfaces';
import { ComponentWrapper, DynamicGrid } from '@iotnxt-dev/reactcomponents';
import { colouredStatus, FormatDateComponent } from './gridOverrides';
import { useLightMode } from '../utils';

interface Props {
    match: {
        params: {
            assetSiNumber: string;
        };
    };
}

interface States {
    asset?: Asset;
    tagStates?: TagStatus[];
    assetStatusHistory?: CloudAppResponse<AssetStatusHistory[]>;
    response_editAsset?: CloudAppResponse<unknown>;
    response_allocateTag?: CloudAppResponse<unknown>;
    stateTypes: IState[];
}

export class AssetPage extends React.Component<Props, States> {
    state: States = {
        asset: undefined,
        tagStates: undefined,
        assetStatusHistory: undefined,
        response_editAsset: undefined,
        response_allocateTag: undefined,
        stateTypes: [],
    };

    componentDidMount(): void {
        this.getAsset();
        this.getTagData();
        this.getStates();
        document.title = 'Conoco Phillips | Asset' + this.props.match.params.assetSiNumber;
    }

    getAsset(): void {
        if (!api.token) throw new Error('missing token');

        fetch(
            api.apiUrl +
                '/api/Asset/GetAsset?' +
                encodeQueryData({ assetSiNumber: this.props.match.params.assetSiNumber }),
            {
                headers: { Authorization: 'Bearer ' + api.token.access_token },
            },
        )
            .then((res) => res.json())
            .then((response: CloudAppResponse<{ [index: string]: unknown }>) => {
                if (response.isSuccessful) {
                    const defaults = {
                        actionDate: new Date().toISOString(),
                        actionBy: api?.account?.preferred_username ?? '[USER NOT FOUND]',
                    };
                    const asset = { ...response.data, ...defaults } as unknown as Asset;
                    this.setState({ asset });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async getTagData(): Promise<void> {
        this.GetAllTagStates();
        const assetStatusHistory = await api.AssetTagState.GetAssetStatusHistory(this.props.match.params.assetSiNumber);
        if (assetStatusHistory) this.setState({ assetStatusHistory });
    }

    getStates(): void {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/DropDowns/GetStates', {
            headers: { Authorization: 'Bearer ' + api.token.access_token },
        })
            .then((res) => res.json())
            .then((response: CloudAppResponse<IState[]>) => {
                if (response.data) this.setState({ stateTypes: response.data });
            })
            .catch(() => {
                this.setState({ stateTypes: [] });
            });
    }

    GetAllTagStates(): void {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/AssetTagState/GetAllAssetTags', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + api.token.access_token },
        })
            .then((res) => res.json())
            .then((response: CloudAppResponse<TagStatus[]>) => {
                if (response.isSuccessful === undefined) {
                    console.log(new Error('Missing isSuccessful property'));
                    return;
                }
                if (response.isSuccessful) {
                    if (response.data) this.setState({ tagStates: response.data });
                } else {
                    console.error(response);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    updateAsset(asset: Asset, cb: (response: CloudAppResponse<unknown>) => void): void {
        if (!api.token) throw new Error('missing token');

        fetch(api.apiUrl + '/api/Asset/UpsertAsset', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + api.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(asset),
        })
            .then((res) => res.json())
            .then(
                (response: CloudAppResponse<unknown>) => {
                    cb(response);
                },
                (err) => {
                    console.error(err);
                    /*
                var resError: CloudAppErrorResponse = {
                    isSuccessful: false,
                     error: err 
                };
                cb(resError);
                */
                },
            );
    }

    render(): JSX.Element {
        if (!this.state.asset) return <Loading text="asset loading" />;
        if (!this.state.tagStates) return <Loading text="tag states" />;
        if (!this.state.assetStatusHistory) return <Loading text="asset status history" />;
        if (!api || !api.token) return <Loading text="waiting for token" />;

        return (
            <ComponentWrapper>
                <h1>Asset Details</h1>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1 }}>
                            <div
                                style={{
                                    background: 'white',
                                    border: '2px solid rgba(0,0,0,0.1)',
                                    borderRadius: 2,
                                }}
                            >
                                <div style={{ padding: 20 }}>
                                    {!this.state.asset && (
                                        <div>
                                            <Loading text="Asset information" />
                                        </div>
                                    )}

                                    {this.state.asset && (
                                        <AssetForm
                                            value={{
                                                ...(JSONpropToCamelCase(this.state.asset) as assetFormType),
                                                isEditing: true,
                                            }}
                                            formChange={(asset) => {
                                                this.setState({ asset: JSONpropToCamelCase(asset) as Asset });
                                            }}
                                        />
                                    )}
                                </div>

                                <div style={{ padding: '0px 30px 25px 0px', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex: 1, padding: 5, paddingRight: 20, textAlign: 'right' }}>
                                        <CloudAppResponseDisplay data={this.state.response_editAsset} />
                                    </div>
                                    <Button
                                        text={'Save Asset Details'}
                                        onClick={() => {
                                            this.setState({ response_editAsset: undefined });

                                            if (this.state.asset)
                                                this.updateAsset(this.state.asset, (response) => {
                                                    //const results = response as CloudAppResponse<unknown>;
                                                    this.setState({ response_editAsset: response }); // results })
                                                    this.getTagData();
                                                });
                                        }}
                                        icon="fal fa-save"
                                    />
                                    <Button
                                        text={'Close'}
                                        component={Link}
                                        icon="far fa-times-circle"
                                        style={{ marginLeft: '1em', backgroundColor: '#d81a2b' }}
                                        onClick={() => window.history.back()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: 20 }}>
                        <h1>Asset History:</h1>
                        <DynamicGrid
                            useLightMode={useLightMode()}
                            isLocked={true}
                            useHistory={false}
                            pageSize={20}
                            showAutoRefreshToggle={true}
                            autoRefresh
                            orderByColumn={'actionDate'}
                            orderByColumnSorting="DESC"
                            conditionalOverrides={[
                                {
                                    onColumnName: 'actionDate',
                                    component: FormatDateComponent,
                                },
                            ]}
                            searchColumnData={{ assetSiNumber: this.props.match.params.assetSiNumber }}
                            config={{
                                server: '',
                                apiPath: '/api/AssetHistoryDynamicGrid',
                                authorization: 'Bearer ' + api.token.access_token,
                            }}
                            conditionalRowStyle={colouredStatus}
                        />
                    </div>
                </div>
            </ComponentWrapper>
        );
    }
}
