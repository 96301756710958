import React from 'react';
import styled from 'styled-components';
import { Button } from './button';

export const FormWrapper = styled.div`
    background: white;
    border-radius: 2px;
    flex: 1;
    box-shadow: 0px 10px 12px -8px rgba(0, 0, 0, 0.25);
    z-index: 101;
    position: relative;
    margin: 50px auto;
`;

export const FormHeader = styled.div`
    background: #a00018;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: row;
`;

export const FormBody = styled.div`
    background: white;
    padding: 14px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const FormFooter = styled.div`
    background: #0c3859;
    display: flex;
    flex-direction: row;
    padding: 14px;
`;

export const CloseButtonDiv = styled.div`
    font-size: 20px;
    cursor: pointer;
    padding: 12px 19px;
    color: rgba(255, 255, 255, 0.75);
    :hover {
        color: white;
        background: rgba(255, 255, 255, 0.25);
    }
`;

export const FillBG = styled.div`
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

interface Props {
    title: string;
    apply: () => Promise<void>;
    close: () => void;
    applyButtonText?: string;
    applyButtonIcon?: string;
}

export const PopupMasterDiv = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    overflow: visible;
`;

export const PopupPadding = styled.div`
    margin: 0 auto;
    padding: 50px;
`;

interface State {
    error?: string;
}

export class DropdownForm extends React.Component<Props, State> {
    state: State = {};

    render(): JSX.Element {
        return (
            <PopupMasterDiv>
                <FillBG
                    onClick={() => {
                        this.props.close();
                    }}
                />

                <PopupPadding>
                    <FormWrapper>
                        <FormHeader>
                            <div style={{ flex: 1, padding: 14 }}>{this.props.title}</div>
                            <CloseButtonDiv onClick={this.props.close}>
                                <i className="fal fa-times" />
                            </CloseButtonDiv>
                        </FormHeader>

                        <FormBody>{this.props.children}</FormBody>

                        <FormFooter>
                            <div style={{ color: 'red', flex: 1, textAlign: 'right', paddingTop: 7, paddingRight: 14 }}>
                                {this.state.error}
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <Button
                                    text={this.props.applyButtonText ? this.props.applyButtonText : 'Save'}
                                    onClick={async () => {
                                        // clear the error before we attempt to apply
                                        this.setState({ error: undefined });
                                        await this.props.apply().catch((error: Error) => {
                                            this.setState({ error: error.message });
                                        });
                                    }}
                                    style={{ background: '#c21f35', borderRadius: 5 }}
                                    icon={
                                        this.props.applyButtonIcon ? this.props.applyButtonIcon : 'fal fa-check-square'
                                    }
                                />
                            </div>
                            <div>
                                <Button
                                    text="Close"
                                    onClick={this.props.close}
                                    style={{ background: '#6d757d', borderRadius: 5 }}
                                    icon="fal fa-times"
                                />
                            </div>
                        </FormFooter>
                    </FormWrapper>
                </PopupPadding>
            </PopupMasterDiv>
        );
    }
}
