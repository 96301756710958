import React from 'react';
import { Button } from './button';
import { ButtonSave, CloudAppResponseDisplay, InputEdit, Table } from './styledComponents';
import {
    CloseButtonDiv,
    FillBG,
    FormBody,
    FormFooter,
    FormHeader,
    FormWrapper,
    PopupMasterDiv,
    PopupPadding,
} from './dropdownForm';
import { CloudAppResponse } from '../shared/cloudappresponse';

interface Props {
    title: string;
    list?: DropdownListEntryType[];
    onChange: (list: DropdownListEntryType[]) => void;
    apply: (body: { id: string; name: string }, cb: (response: CloudAppResponse<unknown>) => void) => void;
    close: () => void;
}

interface State {}

interface DropdownListEntryType {
    id: string;
    name: string;
    Changed?: boolean;
    response?: CloudAppResponse<unknown>;
}

export class DropdownList extends React.Component<Props, State> {
    render(): JSX.Element {
        if (!this.props.list) return <div></div>;

        const list: DropdownListEntryType[] = JSON.parse(
            JSON.stringify(
                this.props.list.sort((a, b) => {
                    if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
                        return 1;
                    } else {
                        return -1;
                    }
                }),
            ),
        );

        return (
            <PopupMasterDiv>
                <FillBG
                    onClick={() => {
                        this.props.close();
                    }}
                />
                <PopupPadding>
                    <FormWrapper>
                        <FormHeader>
                            <div style={{ flex: 1, padding: 14 }}>{this.props.title}</div>
                            <CloseButtonDiv onClick={this.props.close}>
                                <i className="fal fa-times" />
                            </CloseButtonDiv>
                        </FormHeader>

                        <FormBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <td>Id</td>
                                        <td>Name</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.length === 0 && (
                                        <tr>
                                            <td colSpan={2} style={{ textAlign: 'left' }}>
                                                no data
                                            </td>
                                        </tr>
                                    )}
                                    {list.map((entry, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{entry.id}</td>
                                                <td>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: 250 }}>
                                                            <InputEdit
                                                                value={entry.name}
                                                                onChange={({
                                                                    target,
                                                                }: React.FormEvent<HTMLInputElement>) => {
                                                                    const { value } = target as HTMLInputElement;
                                                                    entry.name = value;
                                                                    entry.Changed = true;
                                                                    this.props.onChange(list);
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: 10 }}>
                                                            {entry.Changed && (
                                                                <ButtonSave
                                                                    onClick={() => {
                                                                        this.props.apply(entry, (response) => {
                                                                            entry.response = response;
                                                                            if (response.isSuccessful) {
                                                                                delete entry.Changed;
                                                                                this.props.onChange(list);
                                                                            }
                                                                            if (!response.isSuccessful) {
                                                                                entry.response = response;
                                                                                this.props.onChange(list);
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div style={{ padding: 6, fontSize: 12 }}>
                                                            <CloudAppResponseDisplay data={entry.response} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </FormBody>

                        <FormFooter>
                            <div style={{ flex: 1 }} />
                            <div>
                                <Button
                                    text="Cancel"
                                    onClick={this.props.close}
                                    style={{ background: '#6d757d', borderRadius: 5 }}
                                    icon="fal fa-times"
                                />
                            </div>
                        </FormFooter>
                    </FormWrapper>
                </PopupPadding>
            </PopupMasterDiv>
        );
    }
}
