import React from 'react';
import styled from 'styled-components';

const FooterDiv = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 10px;

    img {
        height: 50px;
    }

    div {
        padding: 5px;
    }
`;

export const Footer: React.FC = (): JSX.Element => {
    return (
        <FooterDiv>
            <div style={{ flex: 1 }} />
            <div>
                <img src="/footer_wires.gif" alt="background" />
            </div>

            <div style={{ paddingRight: 30 }}>
                <a target="_blank" rel="noreferrer" href="https://www.iotnxt.com/">
                    <img src="/footer_logo_iotnxt.gif" alt="iotnxt logo" />
                </a>
            </div>
        </FooterDiv>
    );
};
