import { ThemesType } from '@iotnxt-dev/reactcomponents';
import { Permission } from './components/permissions_page';
import { CloudAppResponse } from './shared/cloudappresponse';
// import { formatDate } from './shared/date';
// import { JSONpropToCamelCase } from './shared/shared';
import {
    Asset,
    AssetStatusHistory,
    AssetType,
    IState,
    Role,
    Site,
    Status,
    TagStatus,
    TagType,
    User,
} from './interfaces';
import { IoTTokenDecoded } from '@iotnxt-dev/shared';
import { assetFormType } from './components/assetForm';
import { CloudAppResponseFormValidation } from './components/assets';

class API {
    account?: IoTTokenDecoded;
    token?: Token;
    // DEV DEFAULTS
    commanderUrl = '';
    apiUrl = '';
    client_id = '';
    theme?: ThemesType;

    getAllPermissions(cb: (permissions: Permission[]) => void) {
        if (!this.token) throw new Error('no token');

        fetch(api.apiUrl + '/api/Permission/GetAllPermissions', {
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        })
            .then((res) => res.json())
            .then((response: CloudAppResponse<Permission[]>) => {
                if (!response.data) throw new Error(response.message || response.exceptionMessage);

                cb(
                    response.data.sort((a, b) => {
                        if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }),
                );
            });
    }

    permissionUpsertPermissionDetail(permission: Permission, cb?: () => void) {
        if (!this.token) throw new Error('no token');

        fetch(api.apiUrl + '/api/Permission/UpsertPermissionDetail', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.token.access_token,
            },
            body: JSON.stringify(permission),
        })
            .then((res) => res.json)
            .then(() => {
                if (cb) cb();
            });
    }

    getSites = async (): Promise<CloudAppResponse<Site[]>> => {
        if (!this.token) throw new Error('Token not ready');

        return fetch(api.apiUrl + '/api/DropDowns/GetSites', {
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    sites = {
        getSites: this.getSites,
    };

    // Asset

    Asset_GetAssets = async (): Promise<CloudAppResponse<Asset[]>> => {
        if (!this.token) throw new Error('Token not ready');
        return await fetch(api.apiUrl + '/api/Asset/GetAssets', {
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'content-type': 'application/json',
            },
        }).then((res) => res.json());
    };

    Asset_GetAsset = async (assetSiNumber: string): Promise<CloudAppResponse<Asset>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + `/api/Asset/GetAsset?${encodeQueryData({ assetSiNumber })}`, {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    /** Call returns `true` if asset with this assetSiNumber exists, `false` if it does not exist */
    Asset_CheckAsset = async (assetSiNumber: string): Promise<CloudAppResponse<boolean>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + `/api/Asset/CheckAsset?${encodeQueryData({ assetSiNumber })}`, {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    Asset_UpsertAsset = async (assetForm: assetFormType): Promise<CloudAppResponse<CloudAppResponseFormValidation>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/Asset/UpsertAsset', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(assetForm),
        }).then((res) => res.json());
    };

    Asset = {
        /** This call is getting assets at the moment. This will become all assets. */
        GetAssets: this.Asset_GetAssets,
        GetAsset: this.Asset_GetAsset,
        CheckAsset: this.Asset_CheckAsset,
        UpsertAsset: this.Asset_UpsertAsset,
    };

    ///////////////

    // AssetTagState

    private AssetTagState_GetAssetStatusHistory = async (
        assetsi: string,
    ): Promise<CloudAppResponse<AssetStatusHistory[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/AssetTagState/GetAssetStatusHistory?' + encodeQueryData({ assetsi }), {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private AssetTagState_GetAllAssetTagHistory = async (): Promise<CloudAppResponse<TagStatus[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/AssetTagState/GetAllAssetTagHistory', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private AssetTagState_GetChartData = async (
        inOrOut: string,
        days?: number,
    ): Promise<CloudAppResponse<unknown[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(`/api/AssetTagState/GetChartData?${encodeQueryData({ inOrOut, days })}`, {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private AssetTagState_GetAllAssetTags = async (): Promise<CloudAppResponse<TagStatus[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/AssetTagState/GetAllAssetTags', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    // todo proper return type on deallocate.
    private AssetTagState_DeallocateAssetTag = async (tag: TagStatus): Promise<CloudAppResponse<unknown>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/AssetTagState/DeallocateAssetTag', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(tag),
        }).then((res) => res.json());
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private AssetTagState_UpsertAssetTags = async (tag: TagStatus): Promise<CloudAppResponse<any>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/AssetTagState/UpsertAssetTags', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(tag),
        }).then((res) => res.json());
    };

    AssetTagState = {
        GetAssetStatusHistory: this.AssetTagState_GetAssetStatusHistory,
        GetAllAssetTagHistory: this.AssetTagState_GetAllAssetTagHistory,
        GetChartData: this.AssetTagState_GetChartData,
        GetAllAssetTags: this.AssetTagState_GetAllAssetTags,
        DeallocateAssetTag: this.AssetTagState_DeallocateAssetTag,
        UpsertAssetTags: this.AssetTagState_UpsertAssetTags,
    };

    ////////////////

    private User_GetAllUsers = async (): Promise<CloudAppResponse<User[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/User/GetAllUsers', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private User_GetUserDetail = async (): Promise<CloudAppResponse<User>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/User/GetUserDetail', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private User_UpsertUserDetail = async (user: User): Promise<CloudAppResponse<User>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/User/UpsertUserDetail', {
            method: 'post',
            headers: {
                accept: 'application/json',
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        }).then((res) => res.json());
    };

    private User_RemoveUserDetails = async (): Promise<CloudAppResponse<User>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/User/RemoveUserDetails', {
            method: 'delete',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private User_GetUserProfile = async (): Promise<CloudAppResponse<User>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/User/GetUserProfile', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private User_InviteNewCommanderUser = async (body: {
        username: string;
        inviteEmail: string;
        firstName: string;
        lastName: string;
    }): Promise<CloudAppResponse<undefined>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/User/InviteNewCommanderUser', {
            method: 'post',
            headers: { Authorization: 'Bearer ' + this.token.access_token, 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        }).then((res) => res.json());
    };

    User = {
        GetAllUsers: this.User_GetAllUsers,
        GetUserDetail: this.User_GetUserDetail,
        UpsertUserDetail: this.User_UpsertUserDetail,
        RemoveUserDetails: this.User_RemoveUserDetails,
        GetUserProfile: this.User_GetUserProfile,
        InviteNewCommanderUser: this.User_InviteNewCommanderUser,
    };

    ////////////////

    private Role_GetAllRoles = async (): Promise<CloudAppResponse<Role[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/Role/GetAllRoles', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private Role_UpsertRoleDetail = async (role: Role): Promise<CloudAppResponse<User>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(api.apiUrl + '/api/Role/UpsertRoleDetail', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(role),
        }).then((res) => res.json());
    };

    Role = {
        GetAllRoles: this.Role_GetAllRoles,
        UpsertRoleDetail: this.Role_UpsertRoleDetail,
    };

    ////////////////

    private DropDowns_GetSites = async (): Promise<CloudAppResponse<Site[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetSites', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_GetSite = async (siteName: string): Promise<CloudAppResponse<Site>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(`/api/DropDowns/GetSite?${encodeQueryData({ siteName })}`, {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_UpsertSite = async (site: Site): Promise<CloudAppResponse<Site[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/UpsertSite', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(site),
        }).then((res) => res.json());
    };

    private DropDowns_GetTagTypes = async (): Promise<CloudAppResponse<TagType[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetTagTypes', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_GetTagType = async (): Promise<CloudAppResponse<TagType>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetTagType', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_UpsertTagType = async (tag: TagType): Promise<CloudAppResponse<Site[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/UpsertTagType', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(tag),
        }).then((res) => res.json());
    };

    private DropDowns_UpsertAssetEntityIds = async (site: Site): Promise<CloudAppResponse<Site[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/UpsertAssetEntityIds', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(site),
        }).then((res) => res.json());
    };

    private DropDowns_GetAssetTypes = async (): Promise<CloudAppResponse<AssetType[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetAssetTypes', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_GetAssetType = async (): Promise<CloudAppResponse<AssetType[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetAssetType', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_UpsertAssetType = async (assetType: AssetType): Promise<CloudAppResponse<AssetType[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/UpsertAssetType', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(assetType),
        }).then((res) => res.json());
    };

    private DropDowns_GetStates = async (): Promise<CloudAppResponse<IState[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetStates', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_GetState = async (): Promise<CloudAppResponse<IState[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetState', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_UpsertState = async (state: IState): Promise<CloudAppResponse<IState[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/UpsertState', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(state),
        }).then((res) => res.json());
    };

    private DropDowns_GetStatuses = async (): Promise<CloudAppResponse<Status[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/GetStatuses', {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_GetStatus = async (statusName: string): Promise<CloudAppResponse<Status>> => {
        if (!this.token) throw new Error('missing token');
        return fetch(`/api/DropDowns/GetStatus?${encodeQueryData({ statusName })}`, {
            method: 'get',
            headers: { Authorization: 'Bearer ' + this.token.access_token },
        }).then((res) => res.json());
    };

    private DropDowns_UpsertStatus = async (status: Status): Promise<CloudAppResponse<Status[]>> => {
        if (!this.token) throw new Error('missing token');
        return fetch('/api/DropDowns/UpsertStatus', {
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + this.token.access_token,
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify(status),
        }).then((res) => res.json());
    };

    DropDowns = {
        GetSites: this.DropDowns_GetSites,
        GetSite: this.DropDowns_GetSite,
        UpsertSite: this.DropDowns_UpsertSite,
        GetTagTypes: this.DropDowns_GetTagTypes,
        GetTagType: this.DropDowns_GetTagType,
        UpsertTagType: this.DropDowns_UpsertTagType,
        UpsertAssetEntityIds: this.DropDowns_UpsertAssetEntityIds,
        GetAssetTypes: this.DropDowns_GetAssetTypes,
        GetAssetType: this.DropDowns_GetAssetType,
        UpsertAssetType: this.DropDowns_UpsertAssetType,
        GetStates: this.DropDowns_GetStates,
        GetState: this.DropDowns_GetState,
        UpsertState: this.DropDowns_UpsertState,
        GetStatuses: this.DropDowns_GetStatuses,
        GetStatus: this.DropDowns_GetStatus,
        UpsertStatus: this.DropDowns_UpsertStatus,
    };
}

// export const api = new API();
const apiinstance = new API();

export const api = apiinstance;

export function encodeQueryData(data: Record<string, unknown>): string {
    const ret = [];
    for (const d in data) ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d] as string)}`);
    return ret.join('&');
}

export function generateDifficult(count: number): string {
    const _sym = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    let str = '';
    for (let i = 0; i < count; i++) {
        const tmp = _sym[Math.round(Math.random() * (_sym.length - 1))];
        str += '' + tmp;
    }
    return str;
}

export interface Token {
    access_token: string;
    expires_in: string;
    expires?: string;
    scope: string;
    state: string;
    token_type: string;
}
