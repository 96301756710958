import React from 'react';
import { URLSearchParamsObj } from '../utils/urlsearchparams';
import { SitePage } from './sitePage';
import { SitesPage } from './sitesPage';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface State {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props {}

export class SitesMain extends React.Component<State> {
    state: State = {};

    render(): JSX.Element {
        const searchparams = URLSearchParamsObj<{ site?: string; id?: string; fullscreen?: string }>();
        if (searchparams.site) {
            return <SitePage site={searchparams.site} id={searchparams.id} />;
        }

        return <SitesPage />;
    }
}
