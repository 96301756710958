import React from 'react';
import { api, encodeQueryData } from '../api';
import { URLSearchParamsObj } from '../utils/urlsearchparams';

import {
    BarGraph,
    ComponentWrapper,
    DynamicGrid,
    GraphDataEntry,
    JsonPropertiesResponse,
    PageResultResponse,
} from '@iotnxt-dev/reactcomponents';

import { API as DynamicGridAPI } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/api';

import { CloudAppResponse } from '../shared/cloudappresponse';
import {
    colouredStatus,
    colourKeys,
    overrideAssetSN,
    overrideSite,
    overrideStatus,
    overrideStatusDate,
} from './gridOverrides';
import { Loading } from './loading';
import { GridConfigType } from '@iotnxt-dev/reactcomponents/build/src/components/dynamic-grid/interfaces';
import { useLightMode } from '../utils';

interface Props {}

interface State {
    chartDataResponse?: CloudAppResponse<GraphDataEntry[]>;
    jsonProperties?: JsonPropertiesResponse;
}

interface DynamicGridDataModel {
    assetSiNumber: string;
    assetName: string;
    assetType: string;
    site: string;
    usState: string;
    nfcTagNumber: string;
    bleTagNumber: string;
    status: string;
    statusDate: string;
    cacheInsertedDate: string;
    cacheLastUpdatedDate: string;
    cacheItemState: string;
    state: string;
    id: string;
}

export class Dashboard extends React.Component<Props, State> {
    state: State = {
        chartDataResponse: undefined,
    };

    config?: GridConfigType;

    componentDidMount = async (): Promise<void> => {
        document.title = 'Conoco Phillips | Dashboard';

        // ----------------------------------------
        // Get GetJsonProperties
        // We get the jsonproperties column data, then override status and assetType enum values
        if (!api || !api.token) {
            throw new Error('missing api or token');
        }
        this.config = {
            server: '',
            apiPath: '/api/DashboardDynamicGrid',
            authorization: 'Bearer ' + api.token.access_token,
        };
        const dynamicGridApi = new DynamicGridAPI();
        dynamicGridApi.config = this.config;
        dynamicGridApi.headers.authorization = this.config.authorization;

        const [chartDataResponse, statuses, assetTypes, getJsonProperties] = await Promise.all([
            this.apiGetChartData(),
            api.DropDowns.GetStatuses(),
            api.DropDowns.GetAssetTypes(),
            dynamicGridApi.GetJsonProperties(),
        ]);
        const jsonProperties = getJsonProperties.data;

        if (statuses.data && assetTypes.data && chartDataResponse && jsonProperties) {
            // override GetJsonProperties status and assetType
            jsonProperties.columns['status'].values = statuses.data.map((s) => s.name);
            jsonProperties.columns['assetType'].values = assetTypes.data.map((a) => a.name);
            this.setState({ jsonProperties, chartDataResponse });
        }
        // ----------------------------------------
    };

    apiGetChartData = async (): Promise<CloudAppResponse<GraphDataEntry[]>> => {
        if (!api.token) throw new Error('token not ready');

        const responseChart: CloudAppResponse<GraphDataEntry[]> = await fetch(
            `/api/AssetTagState/GetChartData?${encodeQueryData({ inOrOut: 'any', days: 30 })}`,
            {
                method: 'get',
                headers: { authorization: 'Bearer ' + api.token.access_token },
            },
        ).then((res) => res.json());

        // workaround to see if we get any valid data from the getChart api call. otherwise we will pull the data manually below.
        if (responseChart.isSuccessful) {
            if (responseChart.data) {
                for (const entry of responseChart.data) {
                    for (const key of Object.keys(entry)) {
                        const e: Record<string, unknown> = entry as unknown as Record<string, unknown>;
                        if (key != 'date' && e[key] != 0) {
                            // must have some valid data?
                            return responseChart;
                        }
                    }
                }
            }
        }

        // so above failed, now we try it bruteforce.

        const response: PageResultResponse<DynamicGridDataModel> = await fetch(
            `/api/DashboardDynamicGrid/GetPagedInstances`,
            {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + api.token.access_token,
                },
                body: JSON.stringify({
                    pageNumber: 1,
                    pageSize: 2000,
                    whereQuery: {},
                    orderByQuery: 'statusDate DESC',
                    selectQuery: '',
                }),
            },
        ).then((res) => res.json());

        if (!response.data) throw new Error('could not get chart data.');

        const chartData: { [index: string]: GraphDataEntry } = {};

        const listOfStatuses: string[] = [];

        response.data.map(({ status }) => {
            if (listOfStatuses.includes(status)) return null;
            listOfStatuses.push(status);
            return null;
        });

        response.data.map(({ status, statusDate }) => {
            const date = formatDateYYYYMMDD(new Date(statusDate).toISOString());

            const newEntry: { [index: string]: GraphDataEntry[] | number | string } = { date };
            listOfStatuses.map((statusname) => {
                newEntry[statusname] = 0;
                return null;
            });

            const existingEntry = chartData[date] ? chartData[date] : JSON.parse(JSON.stringify(newEntry));

            if (existingEntry[status]) {
                existingEntry[status] += 1;
            } else {
                existingEntry[status] = 1;
            }
            chartData[date] = existingEntry;
            return null;
        });

        const result: CloudAppResponse<GraphDataEntry[]> = {
            isSuccessful: response.isSuccessful,
            message: response.message,
            data: Object.keys(chartData).map((datekey) => chartData[datekey]),
        };

        return result;
    };

    render(): JSX.Element {
        if (!api.token) return <div>waiting for auth</div>;
        if (!this.state.jsonProperties) return <Loading text={'Loading Columns'} />;
        const searchParams = URLSearchParamsObj<{ [index: string]: unknown }>();
        delete searchParams.fullscreen;

        return (
            <ComponentWrapper style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ width: '80%' }}>
                    <DynamicGrid
                        useLightMode={useLightMode()}
                        key={JSON.stringify(this.state.jsonProperties)}
                        pageSize={15}
                        columnControls={false}
                        useHistory={false}
                        useExport={true}
                        isLocked={true}
                        searchColumnData={searchParams}
                        orderByColumn="statusDate"
                        orderByColumnSorting="DESC"
                        autoRefresh={true}
                        showAutoRefreshToggle={true}
                        jsonPropertiesOverride={this.state.jsonProperties}
                        onGetPagedInstances={async () => {
                            const chartDataResponse = await this.apiGetChartData();
                            if (chartDataResponse) this.setState({ chartDataResponse });
                        }}
                        config={this.config}
                        conditionalOverrides={[overrideSite, overrideAssetSN, overrideStatus, overrideStatusDate]}
                        conditionalRowStyle={colouredStatus}
                    />
                </div>

                <ComponentWrapper style={{ width: '20%', padding: 30 }}>
                    {this.state.chartDataResponse && this.state.chartDataResponse.isSuccessful === false && (
                        <span style={{ color: 'rgba(218,100,105,1)' }}>
                            Graph could not be loaded:{' '}
                            {this.state.chartDataResponse.exceptionMessage || this.state.chartDataResponse.message}
                        </span>
                    )}

                    {this.state.chartDataResponse &&
                        this.state.chartDataResponse.data &&
                        this.state.chartDataResponse.data.length > 0 && (
                            <BarGraph colourKeys={colourKeys} data={this.state.chartDataResponse.data} />
                        )}
                </ComponentWrapper>
            </ComponentWrapper>
        );
    }
}

// Seems the model changed.. todo fix the other pages.
export interface TagStatusHistory {
    assetSiNumber: string;
    assetType: string;
    tagNumber: string;
    issueDate: string;
    issueType: string;
    status: string;
    tagLastSeen: string;
    actionedBy: string;
}

/** DEPRECATED use interfaces/AssetTagState*/
export interface TagStatusHistoryNew {
    assetSiNumber: string;
    assetName: string;
    assetType: string;
    site: string;
    state: string;
    assetLocation: string;
    nfcTagNumber: string;
    bleTagNumber: string;
    status: string;
    name: string;
    active: boolean;
    actionDate: string;
    tagNumber: string;
    tagStatus: string;
    tagLastSeen: string;
}

export interface AssetStatusHistory {
    assetSiNumber: string;
    assetType: string;
    site: string;
    state: string;
    assetLocation: string;
    nfcTagNumber: string;
    bleTagNumber: string;
    status: string;
    actionDate: string;
    allocatedBy: string;
    deAllocatedBy: string;
}

function formatDateYYYYMMDD(date: string): string {
    const d = new Date(date),
        year = d.getFullYear();
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
