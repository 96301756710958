import React from 'react';
import { Link } from 'react-router-dom';
import { api, encodeQueryData } from '../api';
import { URLSearchParamsObj } from '../utils/urlsearchparams';
import { URLSearchProps } from '../App';
import { Loading } from './loading';
import { Site } from '../interfaces';
import { ComponentWrapper, DynamicGrid } from '@iotnxt-dev/reactcomponents';
import { useLightMode } from '../utils';

interface Props {
    site?: string;
}

export class SitesPage extends React.Component<Props> {
    render(): JSX.Element {
        const searchparams = URLSearchParamsObj<URLSearchProps>();

        if (!api || !api.token) return <Loading />;

        return (
            <ComponentWrapper>
                {searchparams.fullscreen !== 'true' && <h1 style={{ marginLeft: 25 }}>Sites</h1>}
                <DynamicGrid
                    useLightMode={useLightMode()}
                    isLocked={true}
                    useHistory={false}
                    pageSize={20}
                    autoRefresh
                    showAutoRefreshToggle={false}
                    orderByColumn={'totalAssets'}
                    config={{
                        server: '',
                        apiPath: '/api/SitesDynamicGrid',
                        authorization: 'Bearer ' + api.token.access_token,
                    }}
                    conditionalOverrides={[
                        {
                            onColumnName: 'name',
                            component: (props: { value: unknown; row: Site }) => {
                                const params = URLSearchParamsObj<URLSearchProps>();
                                params.site = props.row.name;
                                // params.fullscreen = 'true';
                                return (
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        <Link to={`/sites?${encodeQueryData(params as Record<string, unknown>)}`}>
                                            <i className="fal fa-link" /> {props.row.name}
                                        </Link>
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            </ComponentWrapper>
        );
    }
}
