import React from 'react';
import { Asset, AssetType, IState, Site, Status, TagType } from '../interfaces';
import { CloudAppResponse } from '../shared/cloudappresponse';
import { api } from '../api';
import { FormLabel, FormSelect, FormTable, InputEdit } from './styledComponents';
import { formatDate } from '../shared/date';
import { CloudAppResponseFormValidation } from './assets';

interface Props {
    formChange: (assetForm: assetFormType) => void;
    value?: assetFormType;
    response?: CloudAppResponseFormValidation;
}

interface States {
    sites?: CloudAppResponse<Site[]>;
    assetTypes?: CloudAppResponse<AssetType[]>;
    tagTypes?: CloudAppResponse<TagType[]>;
    states?: CloudAppResponse<IState[]>;
    statuses?: CloudAppResponse<Status[]>;
    assetForm: assetFormType;
}

export interface assetFormType extends Partial<Asset> {
    actionedBy?: string;
    isEditing?: boolean;
}

export class AssetForm extends React.Component<Props, States> {
    state: States = {
        tagTypes: undefined,
        states: undefined,
        statuses: undefined,
        assetForm: {
            status: '',
            assetName: '',
            assetSiNumber: '',
            assetType: '',
            site: '',
            tagType: '',
            usState: '',
            nfcTagNumber: '',
            bleTagNumber: '',

            actionedBy: api?.account?.preferred_username ?? '[USER NOT FOUND]',
            actionDate: new Date().toISOString(),
            isEditing: false,
        },
    };

    componentDidMount(): void {
        if (this.props.value) {
            this.setState({ assetForm: this.props.value });
        }
        this.getData();
    }

    getData = async (): Promise<void> => {
        if (!api.token) throw new Error('missing token');

        const [sites, assetTypes, tagTypes, states, statuses] = await Promise.all([
            api.DropDowns.GetSites(),
            api.DropDowns.GetAssetTypes(),
            api.DropDowns.GetTagTypes(),
            api.DropDowns.GetStates(),
            api.DropDowns.GetStatuses(),
        ]);

        this.setState({ sites, assetTypes, tagTypes, states, statuses });
    };

    changeForm = (value: { assetForm: assetFormType }): void => {
        this.setState(value);
        this.props.formChange(this.state.assetForm); // let parent know of new state.
    };

    render(): JSX.Element {
        if (this.state.sites === undefined) return <div>loading..</div>;
        if (this.state.assetTypes === undefined) return <div>loading..</div>;
        if (this.state.tagTypes === undefined) return <div>loading..</div>;
        if (this.state.states === undefined) return <div>loading..</div>;
        if (this.state.statuses === undefined) return <div>loading..</div>;

        return (
            <FormTable style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <FormLabel>Asset Name:</FormLabel>
                        <td>
                            <InputEdit
                                value={this.state.assetForm.assetName}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLInputElement;
                                    assetForm.assetName = value;
                                    this.changeForm({ assetForm });
                                }}
                            />
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="assetName" />
                        </td>
                    </tr>

                    <tr>
                        <FormLabel>Asset Type:</FormLabel>
                        <td>
                            <FormSelect
                                value={this.state.assetForm.assetType === null ? '' : this.state.assetForm.assetType}
                                onChange={({ target }: React.FormEvent<HTMLSelectElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLSelectElement;
                                    assetForm.assetType = value;
                                    this.changeForm({ assetForm });
                                }}
                            >
                                <option value="" disabled>
                                    Select a Asset Type
                                </option>
                                {this.state.assetTypes.data &&
                                    this.state.assetTypes.data.map((assetType, i) => {
                                        return (
                                            <option key={i} value={assetType.name}>
                                                {assetType.name}
                                            </option>
                                        );
                                    })}
                            </FormSelect>
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="assetType" />
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>Asset SI. #:</FormLabel>
                        <td>
                            <InputEdit
                                value={this.state.assetForm.assetSiNumber}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLInputElement;
                                    assetForm.assetSiNumber = value;
                                    this.changeForm({ assetForm });
                                }}
                                disabled={this.state.assetForm.isEditing}
                            />
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="assetSiNumber" />
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>Status:</FormLabel>
                        <td>
                            <FormSelect
                                value={this.state.assetForm.status}
                                onChange={({ target }: React.FormEvent<HTMLSelectElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLSelectElement;
                                    assetForm.status = value;
                                    this.changeForm({ assetForm });
                                }}
                            >
                                <option value="">Select a Status</option>
                                {this.state.statuses.data &&
                                    this.state.statuses.data.map((status, i) => {
                                        return (
                                            <option key={i} value={status.name}>
                                                {status.name}
                                            </option>
                                        );
                                    })}
                            </FormSelect>
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="status" />
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>State:</FormLabel>
                        <td>
                            <FormSelect
                                value={this.state.assetForm.usState}
                                onChange={({ target }: React.FormEvent<HTMLSelectElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLSelectElement;
                                    assetForm.usState = value;
                                    this.changeForm({ assetForm });
                                }}
                            >
                                <option value="" disabled>
                                    Select a State
                                </option>
                                {this.state.states.data &&
                                    this.state.states.data.map((state, i) => {
                                        return (
                                            <option key={i} value={state.name}>
                                                {state.name}
                                            </option>
                                        );
                                    })}
                            </FormSelect>
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="state" />
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>Site:</FormLabel>
                        <td>
                            <FormSelect
                                value={this.state.assetForm.site}
                                onChange={({ target }: React.FormEvent<HTMLSelectElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLSelectElement;
                                    assetForm.site = value;
                                    this.changeForm({ assetForm });
                                }}
                            >
                                <option value="" disabled>
                                    Select a Site
                                </option>
                                {this.state.sites.data &&
                                    this.state.sites.data.map((site, i) => {
                                        return (
                                            <option key={i} value={site.name}>
                                                {site.name}
                                            </option>
                                        );
                                    })}
                            </FormSelect>
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="site" />
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>Tag Type:</FormLabel>
                        <td>
                            <FormSelect
                                value={this.state.assetForm.tagType}
                                onChange={({ target }: React.FocusEvent<HTMLSelectElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLSelectElement;
                                    assetForm.tagType = value;
                                    this.changeForm({ assetForm });
                                }}
                            >
                                <option value="" disabled>
                                    Select a Tag Type
                                </option>
                                {this.state.tagTypes.data &&
                                    this.state.tagTypes.data.map((tagType, i) => {
                                        return (
                                            <option key={i} value={tagType.name}>
                                                {tagType.name}
                                            </option>
                                        );
                                    })}
                            </FormSelect>
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="tagType" />
                        </td>
                    </tr>

                    <tr>
                        <FormLabel>Action Date:</FormLabel>
                        <td>
                            <InputEdit
                                type="date"
                                value={formatDate(this.state.assetForm.actionDate || new Date().toISOString())}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLInputElement;
                                    assetForm.actionDate = new Date(value).toISOString();
                                    this.setState({ assetForm: assetForm });
                                }}
                            />
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="actionDate" />
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>Performed By:</FormLabel>
                        <td>
                            <InputEdit
                                type="text"
                                disabled={true}
                                placeholder="[User not found]"
                                value={this.state.assetForm.actionedBy}
                            />
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="actionedBy" />
                        </td>
                    </tr>
                    <tr />
                    <tr>
                        <td colSpan={3} style={{ textAlign: 'center', paddingTop: '1m', paddingBottom: 0 }}>
                            <h4>Tag Details:</h4>
                        </td>
                    </tr>
                    <tr>
                        <FormLabel>NFC Tag#:</FormLabel>
                        <td>
                            <InputEdit
                                type="text"
                                placeholder="ksywp192"
                                value={this.state.assetForm.nfcTagNumber}
                                onChange={({ target }: React.FormEvent<HTMLInputElement>) => {
                                    const assetForm = this.state.assetForm;
                                    const { value } = target as HTMLInputElement;
                                    assetForm.nfcTagNumber = value;
                                    this.changeForm({ assetForm });
                                }}
                            />
                        </td>
                        <td>
                            <FormResponse data={this.props.response} field="nfcTagNumber" />
                        </td>
                    </tr>

                    {this.props.response && (
                        <tr>
                            <td colSpan={99} style={{ color: 'red', textAlign: 'center', fontSize: 14 }}>
                                <i className="fa fa-times" /> Error. {this.props.response.title}
                            </td>
                        </tr>
                    )}
                </tbody>
            </FormTable>
        );
    }
}

declare type PropType = {
    field: string;
    data: CloudAppResponseFormValidation | undefined;
};

export function FormResponse(props: PropType): JSX.Element | null {
    if (!props.data) {
        return null;
    }
    if (!props.data.errors) {
        return null;
    }

    if (!props.data.errors[props.field]) {
        return (
            <div style={{ color: 'green' }}>
                <i className="fa fa-check" />
            </div>
        );
    }

    return (
        <div>
            {props.data.errors[props.field].map((text: string, key: number) => {
                return (
                    <div key={key} style={{ color: 'red' }}>
                        <i className="fa fa-times" /> Error. {text}
                    </div>
                );
            })}
        </div>
    );
}
